import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useMemo} from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {Collapse} from "@mui/material";
import Collapsed from "./Collapsed";
import { useLocation} from 'react-router-dom';

function CollapsableNavItem({item, perms, classes, handleClickOpen, opened}) {
    const location = useLocation();
    const {hash, pathname, search} = location;
    let isActive = item.link === pathname;
    if (item.children !== undefined) {
        Object.values(item.children).forEach((value) => {
            if (value.link === pathname) {
                isActive = true;
            }
        })
    }
    let isOpened = useMemo(() => {
        return opened[item.id]
    }, [opened, item]);
    return (
        <>
                <ListItem
                    button
                    key={item.link}
                    onClick={() => handleClickOpen(item)}
                    className={`${classes.navIcon} ${isActive ? classes.navIconActive : ''}`}
                >
                    <ListItemIcon disableTypography={true}
                                  style={{color: 'black', fontSize: '30px'}}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} disableTypography={true}
                                  style={{fontWeight: '600', fontSize: 'Large'}}/>
                    {isOpened ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={isOpened} timeout="auto" unmountOnExit>
                    <Collapsed item={item} perms={perms} currentPath={pathname}
                               classes={classes}/>
                </Collapse>

        </>
    )
}

export default CollapsableNavItem;