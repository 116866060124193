import React, {useState} from "react";
import {Grid, TextField} from '@material-ui/core';
import CustomDialogSave from "../common/CustomDialogSave";
import {FormControl, InputLabel, OutlinedInput} from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {getAllOptimaUsers} from "../../services/userService";


export const UserDialog = ({
                               open,
                               newUser,
                               handleClose,
                               userData,
                               handleSave,
                               setNewUserData
                           }) => {
    const [tempData, setTempData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [wrongPassword, setWrongPassword] = useState(false);
    const handleSaveInternal = () => {
        if (newUser) {
            if (password === null || repeatPassword === null) {
                setWrongPassword(false);
            } else if (password !== repeatPassword) {
                setWrongPassword(true);
            } else {
                setWrongPassword(false);
                setNewUserData(tempData);
                handleSave();
            }
        } else {
            setWrongPassword(false);
            setNewUserData(tempData);
            handleSave();
        }

    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        setTempData({...tempData, 'password': event.target.value});
    }

    const handleChangeRepeatPassword = (event) => {
        setRepeatPassword(event.target.value);

    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOptimaUserSet = (event) => {
        setTempData({...tempData, 'optima_user': event.uuid});
    }

    const handleCloseInternal = () => {
        setPassword(null);
        setRepeatPassword(null);
        handleClose()
    }


    return (
        <CustomDialogSave open={open} handleClose={handleCloseInternal} maxWidth={"sm"} title={'Użytkownik'}
                          handleSave={handleSaveInternal}>
            <Grid item xs={12}>
                <TextField id="outlined-basic" label="Login" variant="outlined" fullWidth
                           onChange={(event) => {
                               setTempData({...tempData, 'username': event.target.value});
                           }}
                           defaultValue={userData.username}/>
            </Grid>
            <Grid item xs={12}>
                <TextField id="outlined-basic" label="Imię" variant="outlined" fullWidth
                           onChange={(event) => {
                               setTempData({...tempData, 'first_name': event.target.value});
                           }}
                           defaultValue={userData.first_name}/>
            </Grid>
            <Grid item xs={12}>
                <TextField id="outlined-basic" label="Nazwisko" variant="outlined" fullWidth
                           onChange={(event, target) => {
                               setTempData({...tempData, 'last_name': event.target.value});
                           }}
                           defaultValue={userData.last_name}/>
            </Grid>
            <Grid item xs={12}>
                <AsyncAutocomplete getData={getAllOptimaUsers} label={'Użytkownik Optima'}
                                   defaultValue={userData.optima_user_full}
                                   onChange={(event, target) => {
                                       handleOptimaUserSet(event)
                                   }}
                                   name={'optima_user'}
                                   disabled={false}
                                   optionLabel={(option) => option.name}/>
            </Grid>
            {newUser ?
                <>
                    <Grid item xs={12}>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Hasło</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => handleChangePassword(event)}
                                value={password}
                                error={wrongPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Powtórz hasło</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => handleChangeRepeatPassword(event)}
                                value={repeatPassword}
                                error={wrongPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid></>
                :
                <></>
            }

        </CustomDialogSave>
    )
};