import React from 'react';

import {getOngoingServiceOrders} from "../../../services/serviceOrdersService";
import OrderStack from "../OrderStack";


function OngoingServiceOrders() {
    const dataToUpdate = ['warehouse', 'user', 'document_type', 'category', 'contractor',
        'email', 'phone_number', 'device', 'priority', 'acceptance_date', 'closing_date', 'description', 'stage'];
    const enabled_actions = ['synchronize']

    return (
        <OrderStack getData={getOngoingServiceOrders}
                    tableTitle={'Trwające zlecenia serwisowe'}
                    disabled={false}
                    dataToUpdate={dataToUpdate}
                    enabled_actions={enabled_actions}
        />
    );
}

export default OngoingServiceOrders;