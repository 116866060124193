import React from "react";

import StandardNavItem from "./StandardNavItem";
import CollapsableNavItem from "./CollapsableNavItem";

function NavItem({item, perms, classes, handleClickOpen, opened, handleClickClose}) {
    return (
        <>
            {item.collapsable ? (
                <CollapsableNavItem item={item} perms={perms} classes={classes} handleClickOpen={handleClickOpen}
                                    opened={opened}/>) : (<StandardNavItem item={item} classes={classes} handleClose={handleClickClose}/>)}

        </>
    )
}

export default NavItem;