import Box from "@mui/material/Box";
import {Grid, TextField} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React from "react";
import {getAllWarehouses} from "../../services/warehouseService";
import {getAllCustomers} from "../../services/customerService";
import {getAllUsers} from "../../services/userService";
import {getAllDevices} from "../../services/deviceService";
import {getAllCategories} from "../../services/categoryService";
import {getAllDocumentTypes} from "../../services/documentService";
import AsyncAutocomplete from "../common/AsyncAutocomplete";


function OrderSummary({
                          setContractorData,
                          disabled,
                          tempOrderData,
                          setTempOrderData,
                          contractorName,
                          setContractorName,
                          deviceName,
                          setDeviceName,
                          setSummaryChanged,
                          contractorDisabled,
                      }) {


    const onContractorChange = async (newValue) => {
        if (newValue) {
            setContractorName(newValue.name);
            setContractorData(newValue);
            changeData('contractor', newValue.uuid)
            changeData('contractor_d', newValue)
        } else {
            setContractorName('');
        }
    }

    const onDeviceChange = async (newValue) => {
        if (newValue) {
            setDeviceName(newValue.name);
            changeData('device_d', newValue);
            changeData('device', newValue.uuid);
        } else {
            setDeviceName('');
        }
    }

    const onValueChange = (event, key) => {
        changeData(key, event.target.value)
    }

    const onObjectChange = async (newValue, key) => {

        if (newValue) {
            changeData(key, newValue.uuid);
            changeData(`${key}_d`, newValue)
        }
    }

    const changeData = (key, value) => {
        let oldData = tempOrderData;
        oldData[key] = value;
        setTempOrderData(oldData);
        setSummaryChanged(true);
    }

    return (
        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllDocumentTypes} label={'Dokument'}
                                           defaultValue={tempOrderData.document_type_d}
                                           onChange={onObjectChange}
                                           name={'document_type'}
                                           disabled={tempOrderData.full_number ? true : false}
                                           optionLabel={(option) => option.name}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="outlined-basic" label="Numer" variant="outlined" fullWidth disabled={true}
                                   value={tempOrderData.full_number}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                            Kontrahent
                            <Divider color={'black'} fullWidth/>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllCustomers} label={'Kontrahent'}
                                           defaultValue={tempOrderData.contractor_d}
                                           onChange={onContractorChange}
                                           disabled={contractorDisabled}
                                           optionLabel={(option) => option.code}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled={false} fullWidth
                                   defaultValue={contractorName} onChange={(e)=> changeData('contractor_name', e.target.value)}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Email" variant="outlined" disabled={contractorDisabled} fullWidth
                                   onChange={(event) => {
                                       onValueChange(event, 'email')
                                   }}
                                   defaultValue={tempOrderData.email}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Numer telefonu" variant="outlined" disabled={contractorDisabled}
                                   fullWidth
                                   onChange={(event) => {
                                       onValueChange(event, 'phone_number')
                                   }}
                                   defaultValue={tempOrderData.phone_number}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                            Kategoria, Urządzenie, Magazyn
                            <Divider color={'black'} fullWidth/>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllCategories} label={'Kategoria'}
                                           defaultValue={tempOrderData.category_d}
                                           onChange={onObjectChange}
                                           name={'category'}
                                           disabled={disabled}
                                           optionLabel={(option) => option.code}/>
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllUsers} label={'Prowadzący'}
                                           defaultValue={tempOrderData.user_d}
                                           onChange={onObjectChange}
                                           name={'user'}
                                           disabled={disabled}
                                           optionLabel={(option) => option.username}/>
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllWarehouses} label={'Magazyn'}
                                           defaultValue={tempOrderData.warehouse_d}
                                           onChange={onObjectChange}
                                           name={'warehouse'}
                                           disabled={disabled}
                                           optionLabel={(option) => option.symbol}/>
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllDevices} label={'Urządzenie'}
                                           defaultValue={tempOrderData.device_d}
                                           onChange={onDeviceChange}
                                           disabled={disabled}
                                           optionLabel={(option) => option.code}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled={true} fullWidth
                                   value={deviceName}/>
                    </Grid>
                    {/*<Grid item xs={8}>*/}
                    {/*    <TextField id="outlined-basic" label="Priorytet" variant="outlined" disabled={disabled}*/}
                    {/*               fullWidth*/}
                    {/*               onChange={(event) => {*/}
                    {/*                   onValueChange(event, 'priority')*/}
                    {/*               }}*/}
                    {/*               defaultValue={tempOrderData.priority}/>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">*/}
                    {/*        Daty i czas realizacji*/}
                    {/*        <Divider color={'black'} fullWidth/>*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <TextField id="outlined-basic" label="Data przyjęcia" variant="outlined" disabled={disabled}*/}
                    {/*               fullWidth*/}
                    {/*               onChange={(event) => {*/}
                    {/*                   onValueChange(event, 'acceptance_date');*/}
                    {/*                   onValueChange(event, 'acceptance_date_formatted')*/}
                    {/*               }}*/}
                    {/*               defaultValue={tempOrderData.acceptance_date_formatted}/>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6}>*/}
                    {/*    <TextField id="outlined-basic" label="Data realizacji" variant="outlined" disabled={disabled}*/}
                    {/*               fullWidth*/}
                    {/*               onChange={(event) => {*/}
                    {/*                   onValueChange(event, 'closing_date');*/}
                    {/*                   onValueChange(event, 'closing_date_formatted');*/}
                    {/*               }}*/}
                    {/*               defaultValue={tempOrderData.closing_date_formatted}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Box>
        </Box>
    )
}

export default OrderSummary;