import {Card, CardContent, FormControlLabel, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Grid, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";

export const OptimaSettings = ({settingsData, handleChangeData}) => {
    return (<Card>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                        Optima
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField id="outlined-basic" label="Konfiguracyjna baza danych Optima" variant="outlined"
                               fullWidth
                               defaultValue={settingsData.optima_config_database}
                               onChange={(e) => {
                                   handleChangeData('optima_config_database', e.target.value)
                               }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField id="outlined-basic" label="Podstawowa baza danych Optima" variant="outlined"
                               fullWidth
                               defaultValue={settingsData['optima_general_database']}
                               onChange={(e) => {
                                   handleChangeData('optima_general_database', e.target.value)
                               }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Switch checked={settingsData.optima_synchronization} onChange={() => {
                            handleChangeData('optima_synchronization', !settingsData.optima_synchronization)
                        }} name="optima-synchro"/>}
                        label="Synchronizować z Optima"
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>)
}