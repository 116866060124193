import {Button, Dialog, Grid} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React from "react";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import {DialogActions, DialogContent} from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialogSave(props) {
    const {children, open, handleClose, handleSave, maxWidth, title, ...other} = props;

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            {...other}
        >
            <AppBar sx={{position: "static", bgcolor: '#FFCB00'}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Box sx={{padding: '40px'}}>
                    <Box sx={{paddingBottom: "20px"}}>
                        <Grid container spacing={2}>
                            {children}
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary">
                    <SaveIcon/> Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDialogSave;