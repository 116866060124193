import Box from "@mui/material/Box";
import {Grid, TextField} from "@material-ui/core";
import React from "react";

function OrderContractor({contractorData}) {
    return (
        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic" label="Kontrahent" variant="outlined" disabled fullWidth
                                   value={contractorData ? contractorData.code : ''}/>
                    </Grid>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled fullWidth
                                   value={contractorData.name}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Kraj" variant="outlined" disabled fullWidth
                                   value={contractorData.country}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Województwo" variant="outlined" disabled fullWidth
                                   value={contractorData.state}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" label="Ulica" variant="outlined" disabled fullWidth
                                   value={contractorData.street}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="outlined-basic" label="Nr domu" variant="outlined" disabled fullWidth
                                   value={contractorData.street_number}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="outlined-basic" variant="outlined" disabled fullWidth
                                   value={contractorData.house_number}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="outlined-basic" label="Miast" variant="outlined" disabled fullWidth
                                   value={contractorData.city}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic" label="Kod pocztowy" variant="outlined" disabled fullWidth
                                   value={contractorData.postal_code}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Poczta" variant="outlined" disabled fullWidth
                                   value={contractorData.post}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default OrderContractor;