import React from "react";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Button,
    Dialog,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import {primary} from '../../theme';
import {DialogActions} from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomerDialog = ({
                                   open,
                                   handleClose,
                                   customerData,
                                   disabled,
                                   tempCustomerData,
                                   setTempCustomerData,
                                   dataChanged,
                                   setDataChanged,
                                   handleSave,
                               }) => {

    const onValueChange = (key, event) => {
        setDataChanged(true);
        setTempCustomerData({...tempCustomerData, [key]: event.target.value})
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <AppBar sx={{position: 'relative', bgcolor: primary}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {'Klient' + (dataChanged ? " *" : "")}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" label="Nazwa" variant="outlined" fullWidth
                                       disabled={disabled} defaultValue={customerData.name} onChange={(e) => {
                                onValueChange('name', e)
                            }}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Kod pocztowy" variant="outlined" fullWidth
                                       disabled={disabled} defaultValue={customerData.postal_code} onChange={(e) => {
                                onValueChange('postal_code', e)
                            }}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Ulica" variant="outlined" fullWidth
                                       disabled={disabled} defaultValue={customerData.street} onChange={(e) => {
                                onValueChange('street', e)
                            }}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Województwo" variant="outlined" fullWidth
                                       disabled={disabled} defaultValue={customerData.state} onChange={(e) => {
                                onValueChange('state', e)
                            }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Miasto" variant="outlined" disabled={disabled}
                                       fullWidth defaultValue={customerData.city} onChange={(e) => {
                                onValueChange('city', e)
                            }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Kraj" variant="outlined" disabled={disabled} fullWidth
                                       defaultValue={customerData.country} onChange={(e) => {
                                onValueChange('country', e)
                            }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="NIP" variant="outlined" disabled={disabled} fullWidth
                                       defaultValue={customerData.tax_number} onChange={(e) => {
                                onValueChange('tax_number', e)
                            }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Numer telefonu" variant="outlined" disabled={disabled}
                                       fullWidth defaultValue={customerData.phone_number} onChange={(e) => {
                                onValueChange('phone_number', e)
                            }}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {disabled ?
                <></> :
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        <SaveIcon/> Zapisz zmiany
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};