import React from 'react';
import {getClosedServiceOrders} from "../../../services/serviceOrdersService";
import OrderStack from "../OrderStack";


function ClosedServiceOrders() {
    return (
        <OrderStack getData={getClosedServiceOrders}
                    tableTitle={'Zakończone zlecenia serwisowe'}
                    disabled={true}
                    dataToUpdate={[]}
        />
    );
}

export default ClosedServiceOrders;