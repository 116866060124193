import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import React, {useState} from "react";
import SimpleTable from "../common/SimpleTable";
import {NoteDialog} from "./NoteDialog";
import {createNote} from "../../services/serviceOrdersService";
import AddIcon from "@mui/icons-material/Add";

function OrderNotes({notes, serviceOrder, disabled}) {
    const columns_notes = [
        {title: "Lp", field: "number", cellStyle: {width: 30}, sortable: false, filterable: false},
        {title: "Data", field: "date", cellStyle: {width: 150, minWidth: 150}, sortable: false, filterable: false},
        {title: "Treść", field: "description", cellStyle: {width: 1150}, sortable: false, filterable: false}
    ]
    const [newNoteData, setNewNoteData] = useState({});
    const [open, setOpen] = useState(false);
    const tableRef = React.useRef();
    const handleCloseDialog = () => {
        setOpen(false);
        setNewNoteData({});
    }

    const handleSaveNote = () => {
        createNote({...newNoteData, 'service_order': serviceOrder}).then(() => {
            handleCloseDialog();
        })

    }

    const handleAddNote = () => {
        setNewNoteData({});
        setOpen(true);
    }

    let actions = disabled ? [] : [
        {
            icon: AddIcon,
            tooltip: 'Dodaj notatkę',
            isFreeAction: true,
            onClick: (event) => {
                handleAddNote()
            }
        }
    ]

    return (

        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SimpleTable
                            tableRef={tableRef}
                            title={'Notatki'}
                            data={notes}
                            columns={columns_notes}
                            options={{
                                pageSize: 10,
                                search: false,
                                pageSizeOptions: [10],
                                sorting: false,
                                headerStyle: {fontSize: "0.9em"},
                            }}
                            style={{fontSize: "0.8em"}}
                            actions={actions}
                        />
                    </Grid>
                </Grid>
            </Box>
            <NoteDialog setNewData={setNewNoteData} handleClose={handleCloseDialog} handleSave={handleSaveNote}
                        open={open}/>
        </Box>
    )
}

export default OrderNotes;