import Box from "@mui/material/Box";
import {Grid, TextField} from "@material-ui/core";
import React from "react";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {getAllCategories} from "../../services/categoryService";
import {getAllStages, getStage} from "../../services/serviceOrdersService";

function OrderDescription({tempOrderData, setTempOrderData, setDescriptionChanged, disabled, stageName, setStageName, stageDisabled}) {

    const onChange = (e) => {
        let data = tempOrderData;
        data['description'] = e.target.value;
        setTempOrderData(data);
        setDescriptionChanged(true);
    }

    const changeData = (key, value) => {
        let oldData = tempOrderData;
        oldData[key] = value;
        setTempOrderData(oldData);
        setDescriptionChanged(true);
    }

    const onStageChange = async (newValue, key) => {

        if (newValue) {
            setStageName(newValue.description);
            changeData(key, newValue.uuid);
            changeData(`${key}_d`, newValue);
        }
    }
    return (
        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Opis" variant="outlined" disabled={disabled} fullWidth
                                   multiline
                                   rows={30}
                                   defaultValue={tempOrderData.description}
                                   onChange={(e) => {
                                       onChange(e)
                                   }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AsyncAutocomplete getData={getAllStages} label={'Status zlecenia'}
                                           defaultValue={tempOrderData.stage_d}
                                           onChange={onStageChange}
                                           name={'stage'}
                                           disabled={disabled || stageDisabled}
                                           optionLabel={(option) => option.code}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField id="outlined-basic" variant="outlined" disabled={true} fullWidth value={stageName}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default OrderDescription;