import axios from "axios";
import {API} from "../constants";

export const getDevice = async (filters) => {
    let url = `${API.DEVICES}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url)
}

export const getAllDevices = async () => {
    return axios.get(`${API.DEVICES}all/`)
}

export const updateDevices = async (uuid, body) => {
    return axios.patch(`${API.DEVICES}${uuid}/`, body)
}