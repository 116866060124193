import React from 'react';

import {getNewServiceOrders} from "../../../services/serviceOrdersService";

import OrderStack from "../OrderStack";


function ServiceOrders() {

    const dataToUpdate = ['warehouse', 'user', 'document_type', 'category', 'contractor', 'contractor_name',
        'email', 'phone_number', 'device', 'description', 'stage'];

    const enabled_actions = ['accept', 'reject']

    return (
        <OrderStack
            getData={getNewServiceOrders}
            disabled={false}
            dataToUpdate={dataToUpdate}
            newForm={true}
            enabled_actions={enabled_actions}
            tableTitle={'Oczekujące zlecenia serwisowe'}
            contractorDisabled={false}
            disabledTabs={true}
            stageDisabled={true}
        />
    );
}

export default ServiceOrders;