import React from 'react';

import OrderStack from "../OrderStack";
import {getRejectedServiceOrders} from "../../../services/serviceOrdersService";


function RejectedServiceOrders() {
    const enabled_actions = ['restore',]

    return (
        <OrderStack getData={getRejectedServiceOrders}
                    tableTitle={'Odrzucone zlecenia serwisowe'}
                    disabled={true}
                    enabled_actions={enabled_actions}
                    dataToUpdate={[]}
        />

    );
}

export default RejectedServiceOrders;