import React, {useState} from 'react';
import Stack from "@mui/material/Stack";
import {UsersTable} from "./UsersTable";
import {UserDialog} from "./UserDialog";
import {createUser, updateUser} from "../../services/userService";
import {UserButtons} from "./UserButtons";


function Users() {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isNewUserOpenDialog, setIsNewUserOpenDialog] = useState(false);
    const [userData, setUserData] = useState({});
    const [newUserData, setNewUserData] = useState({});

    const tableRef = React.useRef();
    const handleClose = () => {
        setIsOpenDialog(false)
    }

    const handleCloseNewUserDialog = () => {
        setIsNewUserOpenDialog(false)
    }

    const handleClickOpen = (event, data) => {
        setIsOpenDialog(true);
        setUserData(data);
        setNewUserData({});
    }

    const handleNewUser = () => {
        if (JSON.stringify(newUserData) !== "{}") {
            createUser(newUserData).then(() => {
                tableRef.current.onQueryChange();
                handleCloseNewUserDialog();
            });
        }
    }
    const handleAddNew = () => {
        setIsNewUserOpenDialog(true);
        setNewUserData({});
    };

    const handleSave = () => {
        if (JSON.stringify(newUserData) !== "{}") {
            updateUser(userData.uuid, newUserData).then(() => {
            handleClose();
            tableRef.current.onQueryChange();
            });
        }
    }

    return (
        <div>
            <Stack spacing={2}>
                {/*<UserButtons handleButtonClick={handleButtonClick}/>*/}
                <UsersTable
                    handleClickOpen={handleClickOpen}
                    tableTitle={'Użytkownicy'}
                    tableRef={tableRef}
                    handleAddNew={handleAddNew}
                />
            </Stack>
            <UserDialog
                setNewUserData={setNewUserData}
                handleClose={handleClose}
                userData={userData}
                open={isOpenDialog}
                handleSave={handleSave}
            />
            <UserDialog
                setNewUserData={setNewUserData}
                handleClose={handleCloseNewUserDialog}
                userData={{}}
                open={isNewUserOpenDialog}
                newUser={isNewUserOpenDialog}
                handleSave={handleNewUser}
            />
        </div>
    );
}

export default Users;