import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import React from "react";
import SimpleTable from "../common/SimpleTable";

function OrderServiceParts({serviceParts}) {

    const columns = [
        {title: "Lp", field: "number", cellStyle: {width: 30}, sortable: false, filterable: false},
        {title: "Kod towaru", field: "product_code", cellStyle: {width: 100}, sortable: false, filterable: false},
        {
            title: "Nazwa towaru",
            field: "product_name",
            cellStyle: {width: 450, minWidth: 250},
            sortable: false,
            filterable: false
        },
        {title: "Serwisant", field: "user", cellStyle: {width: 100}, sortable: false, filterable: false},
        {title: "Magazyn", field: "warehouse", cellStyle: {width: 150}, sortable: false, filterable: false},
        {
            title: "Status pobrania",
            field: "status_collected",
            cellStyle: {width: 200},
            sortable: false,
            filterable: false,
            lookup: {1: 'pobrano', 0: "nie pobrano"}
        },
        {title: "Pobrano", field: "quantity_collected", cellStyle: {width: 100}, sortable: false, filterable: false},
        {
            title: "Status wydania",
            field: "document",
            cellStyle: {width: 200},
            sortable: false,
            filterable: false,
            lookup: {0: 'nie wydano'}
        },
        {title: "Wydano", field: "quantity_released", cellStyle: {width: 100}, sortable: false, filterable: false},
        {
            title: "Zwrot",
            field: "to_return",
            cellStyle: {width: 100},
            sortable: false,
            filterable: false,
            lookup: {false: '-------', true: 'Tak'}
        },
        {
            title: "Fakturować",
            field: "to_invoicing",
            cellStyle: {width: 100},
            sortable: false,
            filterable: false,
            lookup: {false: 'Nie', true: 'Tak'}
        },
    ]
    return (
        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{height: 250, width: '100%'}}>
                            <SimpleTable
                                title={'Części'}
                                data={serviceParts}
                                columns={columns}
                                options={{
                                    pageSize: 10,
                                    search: false,
                                    pageSizeOptions: [10],
                                    sorting: false,
                                    toolbar: false,
                                    headerStyle: {fontSize: "0.9em"}
                                }}
                                style={{fontSize: "0.8em"}}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default OrderServiceParts;