import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {Link} from "react-router-dom";

function Collapsed({item, perms, currentPath, classes}) {

    return (
        <>
            {Object.keys(item.children).map((key, v) => {
                if (perms.includes(key)) {
                    const isActive = item.children[key].link === currentPath;
                    return (
                        <Link to={item.children[key].link} style={{textDecoration: 'none'}}>
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    key={item.children[key].link}
                                    className={`${classes.nested} ${isActive ? classes.nestedActive : ''}`}
                                >
                                    <ListItemText primary={item.children[key].label}/>
                                </ListItem>
                            </List>
                        </Link>)
                } else {
                    return <></>
                }
            })}
        </>
    )
}

export default Collapsed;