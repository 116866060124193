import {Item} from "../common/styles";
import CustomTable from "../common/CustomTable";
import React from "react";
import {getUsers} from "../../services/userService";
import AddIcon from "@mui/icons-material/Add";


export function UsersTable({handleClickOpen, handleChangeRowsPerPage, tableTitle, tableRef, handleAddNew}) {
    return (
        <div>
            <Item elevation={0}>
                <CustomTable
                    getData={getUsers}
                    columns={[
                        {title: "Login", field: "username"},
                        {title: "Imię", field: "first_name"},
                        {title: "Nazwisko", field: "last_name"},
                        {title: "Użytkownik Optima", field: "optima_user_name"},
                    ]}
                    // options={{
                    //     pageSize: 15,
                    // }}
                    onRowClick={handleClickOpen}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    title={tableTitle}
                    tableRef={tableRef}
                    actions={[
                        {
                            icon: AddIcon,
                            tooltip: 'Dodaj użytkownika',
                            isFreeAction: true,
                            onClick: (event) => {
                                handleAddNew()
                            }
                        }
                    ]}
                />
            </Item>
        </div>
    )
}