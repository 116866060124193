import React, {useState} from "react";
import {Item} from "../common/styles";
import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import CustomTable from "../common/CustomTable";
import {
    getShipmentsAwaiting,
    sendShipment,
    updateShipping,
    updateShippingAddress
} from "../../services/shippingService";
import SendIcon from '@mui/icons-material/Send';
import {ShipmentDialog} from "./ShipmentDialog";
import CustomAlert from "../common/CustomAlert";


function ShipmentsAwaiting() {
    const tableRef = React.useRef();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [newData, setNewData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const handleClickShowShipment = (data) => {
        setCurrentData(data);
        setIsDialogOpen(true);
    }
    const closeDialog = () => {
        setIsDialogOpen(false);
        setCurrentData({});
    }

    const handleSave = () => {
        if(newData.shipping_company){
            updateShipping(currentData.uuid, newData).then((response) => {
                tableRef.current.onQueryChange();
            });
        }
        updateShippingAddress(currentData.address.uuid, newData).then((response) => {
            tableRef.current.onQueryChange();
            closeDialog();
            if (response.status === 200) {
                setIsSuccess("success");
                setSuccessMessage('Zaktualizowano.');
            } else {
                setIsSuccess("error");
                setSuccessMessage('Błąd.');
            }
            setIsOpenSuccess(true);
        })

    }


    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={tableRef}
                                    title={'Oczekujące wysyłki'}
                                    getData={getShipmentsAwaiting}
                                    columns={[
                                        {
                                            title: "Zlecenie",
                                            field: "service_order.full_number",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Do wysłania",
                                            field: "default_send",
                                            editable: 'never',
                                            lookup: {true: 'Tak', false: 'Nie'}
                                        },
                                        {
                                            title: "Urządzenie",
                                            field: "service_order.device_name",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Kurier",
                                            field: "shipping_company.name",
                                            editable: 'never',
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: SendIcon,
                                            tooltip: 'Wyślij',
                                            isFreeAction: false,
                                            onClick: (event, value) => {
                                                sendShipment(value.uuid).then((response) => {
                                                    if (response.status === 200) {
                                                        setIsSuccess("success");
                                                        setSuccessMessage('Wysłano.');
                                                    } else {
                                                        setIsSuccess("error");
                                                        setSuccessMessage('Błąd.');
                                                    }
                                                    tableRef.current.onQueryChange();
                                                })
                                            }
                                        }
                                    ]}
                                    onRowClick={(e, data) => handleClickShowShipment(data)}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <ShipmentDialog open={isDialogOpen}
                            data={currentData}
                            disabled={false}
                            handleClose={closeDialog}
                            handleSave={handleSave}
                            newData={newData}
                            setNewData={setNewData}
                            dataChanged={dataChanged}
                            setDataChanged={setDataChanged}
            />
            <CustomAlert isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess}
                         setSuccessMessage={setSuccessMessage} isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
                {successMessage}
            </CustomAlert>
        </div>
    )
}

export default ShipmentsAwaiting;