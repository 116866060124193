import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Grid} from "@material-ui/core";
import {OptimaSettings} from "./OptimaSettings";
import {MailingSettings} from "./MailingSettings";
import SaveIcon from "@material-ui/icons/Save";
import {getGeneralSettings, updateGeneralSettings} from "../../../services/settingService";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomAlert from "../../common/CustomAlert";


function GeneralSettings() {
    const [settingsData, setSettingsData] = useState({});
    const [defaultSettingsData, setDefaultSettingsData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isSuccess, setIsSuccess] = useState('');
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const handleClickSave = async () => {
        delete settingsData.uuid;
        await updateGeneralSettings(defaultSettingsData.uuid, settingsData).then((response) => {
            if (response.status === 200) {
                setIsSuccess("success");
                setSuccessMessage(response.statusText);
            } else {
                setIsSuccess("error");
                setSuccessMessage(response.statusText);
            }
            setIsOpenSuccess(true);
        });
        setDisabled(true);
    }

    const handleChangeData = (key, value) => {
        setSettingsData({...settingsData, [key]: value});
        setDisabled(false);
    }

    useEffect(() => {
        getGeneralSettings().then((response) => {
            setSettingsData(response.data);
            setDefaultSettingsData(response.data);
            setDataLoaded(true);
        })

    }, []);
    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Ustawienia ogólne
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                dataLoaded ? (<OptimaSettings settingsData={settingsData}
                                                              handleChangeData={handleChangeData}/>) : (
                                    <CircularProgress/>)
                            }

                        </Grid>
                        <Grid item xs={12}>
                            {
                                dataLoaded ? (<MailingSettings settingsData={settingsData}
                                                               handleChangeData={handleChangeData}/>) : (
                                    <CircularProgress/>)
                            }

                        </Grid>
                        <Grid item xs={11}></Grid>
                        <Grid item xs={1} style={{justifyContent: "flex-end"}}>
                            <Button disabled={disabled} size="small" onClick={() => {
                                handleClickSave()
                            }}><SaveIcon/>Zapisz</Button></Grid>
                    </Grid>
                </Box>
            </Box>
            <CustomAlert isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess}
                         setSuccessMessage={setSuccessMessage} isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
                {successMessage}
            </CustomAlert>
        </div>
    )
}

export default GeneralSettings;