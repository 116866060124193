import Box from "@mui/material/Box";
import {Grid, TextField} from "@material-ui/core";
import React, {useState} from "react";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {getAllOptimaUsers} from "../../services/userService";
import {getAllProducts} from "../../services/productService";
import {createOrderActivity, getServiceOrderActivities, updateOrderActivity} from "../../services/serviceOrdersService";
import CustomTable from "../common/CustomTable";
import {ActivityDialog} from "./ActivityDialog";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";

function OrderActivities({serviceOrder, disabled}) {
    const tableRef = React.useRef();
    const [activityOpen, setAddActivityOpen] = useState(false);
    const [newData, setNewData] = useState({});
    const [data, setData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [marginValue, setMarginValue] = useState(null);
    const [marginPercent, setMarginPercent] = useState(null);
    const [currentActivity, setCurrentActivity] = useState(null);
    const getTrueFalse = () => {
        return {
            data: [
                {id: 0, name: 'Tak', value: true,},
                {id: 1, name: 'Nie', value: false,},
            ]
        }
    }
    const handleClose = () => {
        setAddActivityOpen(false);
        setData({});
        setMarginValue(null);
        setMarginPercent(null);
        setNewData({});
        setCurrentActivity(null);
    }

    const prepareData = () => {
        let editedData = data;
        if (data.tax_percentage) {
            editedData['tax_percentage'] = data.tax_percentage.uuid;
        }
        if (data.product) {
            editedData['product'] = data.product.uuid;
        }
        console.log(editedData)
        return editedData
    }

    const handleSave = () => {
        if (currentActivity) {
            console.log(currentActivity);
            updateOrderActivity(currentActivity, {...prepareData()}).then(() => {
                tableRef.current.onQueryChange();
                handleClose();
            });
        } else {
            createOrderActivity({...prepareData(), 'service_order': serviceOrder}).then(() => {
                tableRef.current.onQueryChange();
                handleClose();
            });
        }

    }

    const handleClickOpen = async (e, data) => {
        console.log(dayjs(Date()));
        setCurrentActivity(data.uuid);
        if (data.value_net && data.service_cost) {
            let marg = data.value_net - data.service_cost;
            let margPer = (marg * 100) / data.value_net;
            setMarginValue(marg.toFixed(2));
            setMarginPercent(margPer.toFixed(2));
        }
        await setData(data);
        setAddActivityOpen(true);
    }

    const columns_activities = [
        {
            title: "Lp",
            field: "number",
            cellStyle: {width: 20, minWidth: 20, maxWidth: 20},
            sortable: false,
            filterable: false,
            editable: 'never'
        },
        {
            title: "Nazwa",
            field: "product_name",
            cellStyle: {width: 350, minWidth: 350},
            sortable: false,
            filterable: false,
            editable: 'onAdd',
            editComponent: props => (
                <AsyncAutocomplete getData={getAllProducts}
                                   defaultValue={props.value}
                                   onChange={e => props.onChange(e)}
                                   variant={"standard"}
                                   optionLabel={(option) => option.name}/>
            )
        },
        {
            title: "Serwisant",
            field: "user_code",
            cellStyle: {width: 100, minWidth: 100},
            sortable: false,
            filterable: false,
            editable: 'onAdd',
            editComponent: props => (
                <AsyncAutocomplete getData={getAllOptimaUsers}
                                   defaultValue={props.value}
                                   onChange={e => props.onChange(e)}
                                   variant={"standard"}
                                   optionLabel={(option) => option.code}/>
            )
        },
        {
            title: "Termin od",
            field: "date_from",
            cellStyle: {width: 100, minWidth: 100},
            sortable: false,
            filterable: false,
            // editable: 'onAdd',
            editComponent: props => (
                <TextField
                    fullWidth
                    defaultValue={props.value}
                    type="date"
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: "Termin do",
            field: "date_to",
            cellStyle: {width: 100, minWidth: 100},
            sortable: false,
            filterable: false,
            // editable: 'onAdd',
            editComponent: props => (
                <TextField
                    fullWidth
                    defaultValue={props.value}
                    type="date"
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        // {
        //     title: "Czas trwania",
        //     field: "description",
        //     cellStyle: {width: 100, minWidth: 100},
        //     sortable: false,
        //     filterable: false,
        //     editable: 'never'
        // },
        {
            title: "Zakończona",
            field: "is_finished",
            cellStyle: {width: 50},
            sortable: false,
            filterable: false,
            editable: 'onAdd',
            lookup: {true: 'Tak', false: 'Nie'},
            editComponent: props => (
                <AsyncAutocomplete getData={getTrueFalse}
                                   defaultValue={props.value}
                                   onChange={e => props.onChange(e.value)}
                                   variant={"standard"}
                                   optionLabel={(option) => option.name}/>
            )
        },
        {
            title: "Fakturować",
            field: "to_invoicing",
            cellStyle: {width: 50},
            sortable: false,
            filterable: false,
            editable: 'onAdd',
            lookup: {true: 'Tak', false: 'Nie'},
            editComponent: props => (
                <AsyncAutocomplete getData={getTrueFalse}
                                   defaultValue={props.value}
                                   onChange={e => props.onChange(e.value)}
                                   variant={"standard"}
                                   optionLabel={(option) => option.name}/>
            )
        },
        {
            title: "Ilość",
            field: "quantity",
            cellStyle: {width: 50},
            sortable: false,
            filterable: false,
            // editable: 'onAdd',
            editComponent: props => (
                <TextField
                    fullWidth
                    defaultValue={props.value}
                    type="number"
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        // {title: "Status", field: "description", cellStyle: {width: 450}, sortable: false, filterable: false},
    ]

    let actions = disabled ? [] : [
        {
            icon: AddIcon,
            tooltip: 'Dodaj czynność',
            isFreeAction: true,
            onClick: (event) => {
                setData({...data, 'date_of_service': dayjs(Date()).format('YYYY-MM-DD')});
                setAddActivityOpen(true);
            }
        }
    ]

    return (
        <Box sx={{padding: '40px'}}>
            <Box sx={{paddingBottom: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTable
                            tableRef={tableRef}
                            title={'Czynności'}
                            getData={getServiceOrderActivities}
                            columns={columns_activities}
                            options={{
                                pageSize: 10,
                                search: false,
                                pageSizeOptions: [10],
                                sorting: false,
                                headerStyle: {fontSize: "0.9em"},
                            }}
                            style={{fontSize: "0.8em"}}
                            onRowClick={handleClickOpen}
                            query_filters={{'service_order__uuid': serviceOrder}}
                            actions={actions}
                        />
                    </Grid>
                </Grid>
            </Box>
            <ActivityDialog open={activityOpen}
                            newData={newData}
                            setNewData={setNewData}
                            handleClose={handleClose}
                            handleSave={handleSave}
                            dataChanged={dataChanged}
                            setDataChanged={setDataChanged}
                            data={data}
                            setData={setData}
                            marginValue={marginValue}
                            setMarginValue={setMarginValue}
                            marginPercent={marginPercent}
                            setMarginPercent={setMarginPercent}
            />
        </Box>


    )
}

export default OrderActivities;