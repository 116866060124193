import Box from "@mui/material/Box";
import {Button, Dialog, Grid, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {Transition} from "../common/Transition";
import {DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {getAttributesDefinitionItems, updateAttribute} from "../../services/serviceOrdersService";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

function OrderAttributes({attributes, disabled}) {
    const [open, setDialogOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [format, setFormat] = useState(0);
    const [attributeDefinitionId, setAttributeDefinitionId] = useState(null);
    const [changedData, setChangedData] = useState({});

    const handleClickOpen = (data) => {
        setAttributeDefinitionId(data.attribute_definition);
        setFormat(data.attribute_definition_format);
        setSelectedValue(data);
        setDialogOpen(true);
    }

    const onChangeValue = (value) => {
        setChangedData({'value': value});
    }

    const fetchAttributesDefinitions = () => {
        return getAttributesDefinitionItems({'attribute_definition__uuid': attributeDefinitionId})
    }
    const onSaveButton = () => {
        updateAttribute(selectedValue.uuid, changedData).then(() => {
            setDialogOpen(false);
            setSelectedValue({});
            setChangedData({});
        })

    }

    const onCloseButton = () => {
        setDialogOpen(false);
        setSelectedValue({});
        setChangedData({});
    }


    const definitions = {
        //FORMAT 3 - lista FORMAT 4 - data
        3: (<>
            <AsyncAutocomplete getData={fetchAttributesDefinitions}
                               defaultValue={selectedValue}
                               onChange={(e) => onChangeValue(e.value)}
                // name={'category'}
                // disabled={disabled}
                               optionLabel={(option) => option.value}/>
        </>),
        4: (<>
                <TextField id="outlined-basic"
                           variant="outlined"
                           fullWidth
                           defaultValue={selectedValue}
                    // focused={true}
                           onChange={(e) => {
                               onChangeValue(e.target.value)
                           }}
                           type="date"

                /></>
        ),
        1: (<>
                <TextField id="outlined-basic"
                           variant="outlined"
                           fullWidth
                           defaultValue={selectedValue}
                    // focused={true}
                    //        onChange={(e) => {
                    //            changeFormData('purchase_date', e.target.value)
                    //        }}


                /></>
        )
    }

    return (
        <>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography style={{fontSize: "0.8em"}}>Atrybut</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{fontSize: "0.8em"}}>Wartosc</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                            <List>
                                {
                                    attributes.map((e) => {
                                        return (
                                            <>
                                                <ListItem key={e.id} button onClick={disabled ? () => {
                                                } : () => handleClickOpen(e)}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <ListItemText primary={e.code} disableTypography={true}
                                                                          style={{fontSize: "0.8em"}}/>
                                                        </Grid>
                                                        <ListItemText primary={e.value} disableTypography={true}
                                                                      style={{fontSize: "0.8em"}}/>
                                                    </Grid>
                                                </ListItem>
                                                <Divider/>
                                            </>
                                        )
                                    })
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">{"Zmiana atrybutu - " + selectedValue.code}</DialogTitle>
                <DialogContent>
                    <Box sx={{padding: '20px', width: '400px'}}>
                        <Box sx={{paddingBottom: "20px"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography>Atrybut</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>{selectedValue.code}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Wartość</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    {definitions[format]}
                                </Grid>
                            </Grid>

                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSaveButton}>
                        Zapisz
                    </Button>
                    <Button onClick={onCloseButton} color="red" autoFocus>
                        Anuluj
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default OrderAttributes;
