import React, {useState} from "react";
import {Grid, Button, TextField, Paper} from "@material-ui/core";
import {getAuthenticated, login} from "../../services/authenticationService";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import Logo from "../../static/images/wagner_footer.png";
import CustomAlert from "../common/CustomAlert";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    loginBackground: {
        background: "#FFCB00",
        background: `-webkit-linear-gradient(
      to bottom,
      #FFCB00,
      #000000
    )`,
        background: `linear-gradient(
      to bottom,
      #FFCB00,
      black
    )`,
        minHeight: "100vh"
    },
    loginTitle: {
        fontSize: "2em"
    },
    loginContainer: {
        width: "28vw",
        padding: 50,
        [theme.breakpoints.down("sm")]: {
            width: "80vw",
            padding: 15
        }
    },
    logo: {
        width: "100%",
        height: "auto",
        maxWidth: 530
    }
}));

function Login({setIsAuthenticated}) {
    const classes = useStyles();

    const [form, setForm] = useState({username: "", password: ""});
    // const history = useHistory();
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleChange = e => {
        const {name, value, keyCode} = e.target;
        if (keyCode === 13) {
            return handleSubmit();
        }

        setForm({...form, [name]: value});
    };

    const handleSubmit = async () => {
        try {
            await login(form).then((response) => {
                if (!response) {
                    setIsSuccess('error');
                    setIsOpenSuccess(true);
                    setSuccessMessage('Błędny login lub hasło.')
                } else {
                    // goToPage(history, '/dashboard');
                }
            });
            setIsAuthenticated(getAuthenticated());

        } catch (error) {
            console.error(error);
        }
    };

    const handleFormSubmit = e => {
        e.preventDefault();
        handleSubmit();
    };

    return (

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.loginBackground}
        >
            <Grid item style={{maxHeight: 300}}>
                <img src={Logo} className={classes.logo} alt="Logo image"/>
            </Grid>
            <Grid item>
                <form onSubmitCapture={handleFormSubmit}>
                    <Paper className={classes.loginContainer} square elevation={10}>
                        <Grid item container direction="column" spacing={6}>

                            <Grid item>
                                <TextField
                                    color='primary'
                                    onChange={handleChange}
                                    value={form.login}
                                    label="Login"
                                    name="username"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    value={form.password}
                                    label="Hasło"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleSubmit}
                                    variant="contained"
                                    backgroundColor="#FFCB00"
                                    fullWidth
                                    type="submit"
                                >
                                    Zaloguj
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
                <CustomAlert isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess}
                             setSuccessMessage={setSuccessMessage} isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
                    {successMessage}
                </CustomAlert>
            </Grid>
        </Grid>

    );
}

export default Login;
