import React, {useState} from "react";
import {Item} from "../common/styles";
import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import CustomTable from "../common/CustomTable";
import {downloadShippingLabel, getShipmentsCompleted} from "../../services/shippingService";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {ShipmentDialog} from "./ShipmentDialog";


function ShipmentsCompleted() {
    const tableRef = React.useRef();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [trackingDisabled, setTrackingDisabled] = useState(true);
    const handleClickShowShipment = (data) => {
        if (data.track_ids.length !== 0) {
            setTrackingDisabled(false);
        }
        setCurrentData(data);
        setIsDialogOpen(true);
    }
    const closeDialog = () => {
        setTrackingDisabled(true);
        setIsDialogOpen(false);
        setCurrentData({});
    }
    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={tableRef}
                                    title={'Zrealizowane wysyłki'}
                                    getData={getShipmentsCompleted}
                                    columns={[
                                        {
                                            title: "Zlecenie",
                                            field: "service_order.full_number",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Numer przesyłki",
                                            field: "track_ids",
                                            editable: 'never',
                                            render: rowData => rowData.track_ids.length >= 1 ? rowData.track_ids.join(',') : rowData.track_ids
                                        },
                                        {
                                            title: "Urządzenie",
                                            field: "service_order.device_name",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Kurier",
                                            field: "shipping_company.name",
                                            editable: 'never',
                                        },
                                    ]}
                                    actions={[
                                        rowData => ({
                                            icon: SimCardDownloadIcon,
                                            tooltip: 'Pobierz etykietę',
                                            isFreeAction: false,
                                            disabled: !rowData.label,
                                            onClick: (event, value) => {
                                                downloadShippingLabel(value.uuid).then((response) => {
                                                    tableRef.current.onQueryChange();
                                                })
                                            }
                                        })
                                    ]}
                                    onRowClick={(e, data) => handleClickShowShipment(data)}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <ShipmentDialog open={isDialogOpen}
                            data={currentData}
                            disabled={true}
                            handleClose={closeDialog}
                            trackingDisabled={trackingDisabled}
            />
        </div>
    )
}

export default ShipmentsCompleted;