import Stack from "@mui/material/Stack";
import {OrdersTable} from "./OrdersTable";
import {OrderDialog} from "./OrderDialog";
import React, {useEffect, useState} from "react";
import {getCustomers} from "../../services/customerService";
import {getWarehouses} from "../../services/warehouseService";
import {getUsers} from "../../services/userService";
import {getDevice} from "../../services/deviceService";
import {getCategories} from "../../services/categoryService";
import {getDocumentTypes} from "../../services/documentService";
import {
    getAttributes,
    getNotes,
    getStage,
    synchronizeServiceOrder,
    updateServiceOrder
} from "../../services/serviceOrdersService";
import CustomConfirmDialog from "../common/CustomConfirmDialog";
import CustomAlert from "../common/CustomAlert";
import SaveIcon from "@material-ui/icons/Save";
import {MdOutlineCancel, MdOutlineRestore} from "react-icons/md";
import CachedIcon from '@mui/icons-material/Cached';

function OrderStack({
                        getData,
                        tableTitle,
                        disabled,
                        dataToUpdate,
                        disabledTabs,
                        enabled_actions = [],
                        newForm = false,
                        contractorDisabled = true,
                        stageDisabled = false,
                    }) {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [orderData, setOrderData] = useState({});
    const [newOrderData, setNewOrderData] = useState({});
    const [contractorData, setContractorData] = useState({});
    const [notes, setNotes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [summaryChanged, setSummaryChanged] = useState(false);
    const [descriptionChanged, setDescriptionChanged] = useState(false);
    const [contractorName, setContractorName] = useState('');
    const [stageName, setStageName] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [value, setValue] = useState(newForm ? 8 : 0);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [actions, setActions] = useState([]);
    const tableRef = React.useRef();

    const required = {
        'email': 'E-mail',
        'phone_number': 'Numer telefonu' ,
        'device': 'Urządzenie',
        'device_code': 'Kod urządzenia',
        'device_name': 'Nazwa urządzenia',
        'warehouse': 'Magazyn',
        'document_type': 'Typ dokumentu',
        'document_type_name': 'Typ dokumentu'
    }

    const fetchWarehouse = async (filter) => {
        let result = await getWarehouses(filter);
        return result.data.results[0]
    }
    const fetchNotes = async (filter) => {
        let result = await getNotes(filter);
        setNotes(result.data);
    }

    const fetchContractor = async (filter) => {
        let result = await getCustomers(filter);
        return result.data.results[0]
    }

    const fetchUser = async (filter) => {
        let result = await getUsers(filter);
        return result.data.results[0]
    }

    const fetchDevice = async (filter) => {
        let result = await getDevice(filter);
        return result.data.results[0]
    }

    const fetchCategory = async (filter) => {
        let result = await getCategories(filter);
        return result.data.results[0]
    }

    const fetchDocumentType = async (filter) => {
        let result = await getDocumentTypes(filter);
        return result.data.results[0]
    }

    const fetchStage = async (filter) => {
        let result = await getStage(filter);
        return result.data.results[0]
    }

    const fetchAttributes = async (filter) => {
        let result = await getAttributes(filter);
        setAttributes(result.data);
    }
    const fetchContractorData = async (uuid) => {
        let result = await getCustomers({'uuid': uuid})
        setContractorData(result.data.results[0]);
    }

    const fetchData = async (data) => {
        let user, warehouse, contractor, device, category, documentType, stage = {};
        await fetchNotes({'service_order__uuid': data.uuid});
        await fetchAttributes({'service_order__uuid': data.uuid})
        if (data.warehouse) {
            warehouse = await fetchWarehouse({'uuid': data.warehouse});
        }
        if (data.contractor) {
            contractor = await fetchContractor({'uuid': data.contractor});
            setContractorName(data.contractor_name)
        }
        if (data.user) {
            user = await fetchUser({'uuid': data.user});
        }
        if (data.device) {
            device = await fetchDevice({'uuid': data.device});
            setDeviceName(device.name);
        }
        if (data.category) {
            category = await fetchCategory({'uuid': data.category});
        }
        if (data.document_type) {
            documentType = await fetchDocumentType({'uuid': data.document_type});
        }
        if (data.stage) {
            stage = await fetchStage({'uuid': data.stage});
            setStageName(stage.description)
        }

        return {
            'warehouse_d': warehouse,
            'contractor_d': contractor,
            'user_d': user,
            'device_d': device,
            'category_d': category,
            'document_type_d': documentType,
            'stage_d': stage,
        }
    }

    const handleClickOpen = (event, data) => {
        fetchContractorData(data.contractor);
        fetchData(data).then((result) => {
            setValue(newForm ? 8 : 0);
            setOrderData({...data, ...result});
            setNewOrderData({...data, ...result});
            setIsOpenDialog(true);
        })
    }

    const handleCloseCheck = () => {
        if (summaryChanged === false && descriptionChanged === false) {
            handleClose()
        } else {
            setIsOpenDialog(false);
            setConfirmationOpen(true);
        }
    }

    const onGoBack = () => {
        setIsOpenDialog(true);
        setConfirmationOpen(false);
    }

    const handleClose = () => {
        setConfirmationOpen(false);
        setNewOrderData({});
        setSummaryChanged(false);
        setDescriptionChanged(false);
        setIsOpenDialog(false)
    }

    const prepareData = () => {
        let updatedData = {};

        dataToUpdate.forEach((key) => {
            if (newOrderData[key] !== orderData[key]) {
                updatedData[key] = newOrderData[key];
            }
        })
        return updatedData
    }
    const updateOrder = async () => {
        let data = prepareData();
        updateServiceOrder(orderData.uuid, data).then(() => {
            tableRef.current.onQueryChange();
        });
    }

    const handleSave = () => {
        updateOrder().then(() => {
            handleClose()
        });
    }

    const handleAccept = (event, rowData) => {
        const requiredFields = ['email', 'phone_number', 'device', 'warehouse', 'document_type',]
        let isRequiredFilled = true;
        let noFilled = []
        Object.keys(rowData).forEach((key) => {
            if (requiredFields.includes(key)) {
                let val = rowData[key];
                if (val === null || val === '-' || val === '') {
                    isRequiredFilled = false;
                    noFilled.push(required[key]);
                }
            }
        })
        if (!rowData["contractor_ext_id"]){
            setIsSuccess("error");
            setSuccessMessage('Brak kontrahenta w Optima.\nNajpierw dodaj kontrahenta');
            setIsOpenSuccess(true);
        }
        else if (isRequiredFilled) {
            updateServiceOrder(rowData.uuid, {'state': 0}).then(() => {
                setIsSuccess("success");
                setSuccessMessage('Zaakceptowano zgłoszenie.');
                setIsOpenSuccess(true);
                tableRef.current.onQueryChange();
            })
        } else {
            setIsSuccess("error");
            setSuccessMessage('Dane zgłoszenia niekompletne.\nBrakujące pola: ' + noFilled.join(', '));
            setIsOpenSuccess(true);
        }
    }

    const handleReject = (event, rowData) => {
        updateServiceOrder(rowData.uuid, {'state': 3}).then((result) => {
            if (result.status === 200) {
                setIsSuccess("success");
                setSuccessMessage('Zgłoszenie odrzucone.');
                setIsOpenSuccess(true);
            } else {
                setIsSuccess("error");
                setSuccessMessage(result.statusText);
                setIsOpenSuccess(true);
            }

            tableRef.current.onQueryChange();
        })
    }

    const handleRestore = (event, rowData) => {
        updateServiceOrder(rowData.uuid, {'state': 99}).then((result) => {
            if (result.status === 200) {
                setIsSuccess("success");
                setSuccessMessage('Zgłoszenie przywrócone.');
                setIsOpenSuccess(true);
            } else {
                setIsSuccess("error");
                setSuccessMessage(result.statusText);
                setIsOpenSuccess(true);
            }

            tableRef.current.onQueryChange();
        })
    }
    const handleSynchronize = (event, rowData) => {
        synchronizeServiceOrder(rowData.uuid).then((result) => {
                if (result.status === 200) {
                    setIsSuccess("success");
                    setSuccessMessage('Synchronizacja uruchomiona.');
                    setIsOpenSuccess(true);
                } else {
                    setIsSuccess("error");
                    setSuccessMessage(result.statusText);
                    setIsOpenSuccess(true);
                }
            }
        )
    }

    const actions_mapping =
        {
            'accept': {
                icon: SaveIcon,
                tooltip: 'Zaakceptuj',
                onClick: (event, rowData) => {
                    handleAccept(event, rowData)
                }
            },
            'reject': {
                icon: MdOutlineCancel,
                tooltip:
                    'Odrzuć',
                onClick:
                    (event, rowData) => {
                        handleReject(event, rowData)
                    }
            },
            'restore': {
                icon: MdOutlineRestore,
                tooltip:
                    'Przywróć',
                onClick:
                    (event, rowData) => {
                        handleRestore(event, rowData)
                    }
            },
            'synchronize': {
                icon: CachedIcon,
                tooltip:
                    'Synchronizuj Optima',
                onClick:
                    (event, rowData) => {
                        handleSynchronize(event, rowData)
                    }
            }
        }

    useEffect(() => {
        let actions_temp = []
        enabled_actions.forEach((k) => {
                actions_temp.push(actions_mapping[k])
            }
        )
        setActions(actions_temp);
    }, []);
    return (
        <div>
            <Stack spacing={2}>
                <OrdersTable
                    getData={getData}
                    handleClickOpen={handleClickOpen}
                    tableTitle={tableTitle}
                    tableRef={tableRef}
                    actions={actions}
                />
            </Stack>
            <OrderDialog
                handleClose={handleCloseCheck}
                contractorData={contractorData}
                setContractorData={setContractorData}
                open={isOpenDialog}
                disabled={disabled}
                handleSave={handleSave}
                tempOrderData={newOrderData}
                setTempOrderData={setNewOrderData}
                summaryChanged={summaryChanged}
                setSummaryChanged={setSummaryChanged}
                contractorName={contractorName}
                setContractorName={setContractorName}
                deviceName={deviceName}
                setDeviceName={setDeviceName}
                setOrderData={setOrderData}
                descriptionChanged={descriptionChanged}
                setDescriptionChanged={setDescriptionChanged}
                value={value}
                setValue={setValue}
                newForm={newForm}
                notes={notes}
                stageName={stageName}
                setStageName={setStageName}
                attributes={attributes}
                orderData={orderData}
                contractorDisabled={contractorDisabled}
                disabledTabs={disabledTabs}
                stageDisabled={stageDisabled}
            />
            <CustomConfirmDialog handleClose={handleClose} open={isConfirmationOpen} onGoBack={onGoBack}>
                <div><p>Masz <b>niezapisane</b> zmiany w zgłoszeniu serwisowym.</p>
                    <p> Czy napewno chcesz opuścić formularz ?</p></div>
            </CustomConfirmDialog>
            <CustomAlert isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess}
                         setSuccessMessage={setSuccessMessage} isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
                {successMessage}
            </CustomAlert>
        </div>
    );
}

export default OrderStack;