import React from "react";
import CustomTable from "../common/CustomTable";
import {Item} from "../common/styles";
export function CustomersTable({
    getData,
    handleChangeRowsPerPage,
    handleClickOpen,
    tableTitle,
    tableRef,
    actions
    }) {
        return (
            <div>
                <Item elevation={0}>
                    <CustomTable
                        getData={getData}
                        columns = {[
                            {title: "Nazwa", field: "name"},
                        ]}
                        // options={{
                        //     actionsColumnIndex: -1,
                        //     pageSize: 15,
                        // }}
                        onRowClick={handleClickOpen}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        title={tableTitle}
                        tableRef={tableRef}
                        actions={actions}
                    />
                </Item>
            </div>
        );
};