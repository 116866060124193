import axios from 'axios';
import {API} from '../constants';


export const login = async (form) => {
    try {
        removeTokenFromStorage();
        const response = await axios.post(API.AUTH, form);
        if (response) {
            const token = response.data.access;
            const refresh = response.data.refresh;
            const user = response.data.user;
            const perms = response.data.permissions;
            saveTokenToLocalStorage(token);
            saveRefreshToLocalStorage(refresh);
            saveUserToLocalStorage(user);
            savePermissionsToLocalStorage(perms);
            setAuthenticated(true);
            return true;
        }
        // return;
    } catch (error) {
        // throw error;
        return false;
    }
};

export const logout = async () => {
    removeAuthenticated();
    try {
        removeTokenFromStorage();
        removeUserFromStorage();
        removePermissionsFromStorage();


    } catch (error) {
        console.log(error);
    }
};

export const refresh = async () => {
    try {
        const token = localStorage.getItem("refresh");
        const config = {
            refresh: `${token}`
        };
        const response = await axios.post(API.REFRESH, config);
        const newToken = response.data.access;
        saveTokenToLocalStorage(newToken);
        return [getToken(), true]
    } catch (error) {
        if (localStorage.getItem("refresh") && localStorage.getItem("token")) {
            logout();
            window.location.assign('/');
        }

        return;
    }

};

const saveUserToLocalStorage = (user) => {
    localStorage.setItem('firstName', user.first_name);
    localStorage.setItem('lastName', user.last_name);

};

const saveTokenToLocalStorage = (token) => {
    localStorage.setItem('token', token);

};
const setAuthenticated = () => {
    localStorage.setItem('authenticated', true);

};

const removeAuthenticated = () => {
    localStorage.removeItem('authenticated')
}
const saveRefreshToLocalStorage = (refresh) => {
    localStorage.setItem('refresh', refresh);
};
const savePermissionsToLocalStorage = (perms) => {
    localStorage.setItem('permissions', perms);

};
const removeTokenFromStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
};

const removeUserFromStorage = () => {
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
};
const removePermissionsFromStorage = () => {
    localStorage.removeItem('permissions');

};

export const getAuthenticated = () => {return !!localStorage.getItem('authenticated')};
export const getToken = () => localStorage.getItem('token');
export const getRefresh = () => localStorage.getItem('refresh');
export const getFirstName = () => localStorage.getItem('firstName');
export const getLastName = () => localStorage.getItem('lastName');
export const getModelPermissions = () => {
    const perms = localStorage.getItem('permissions');
    const json_perms = JSON.parse(perms);
    let models = [];
    try {
        models = json_perms.models;
    } catch (error) {
    }
    return models;
};