import {Item} from "../common/styles";
import CustomTable from "../common/CustomTable";
import React from "react";

export function OrdersTable({handleClickOpen, handleChangeRowsPerPage, tableTitle, getData, tableRef, actions}) {
    return (
        <div>
            <Item elevation={0}>
                <CustomTable
                    getData={getData}
                    columns={[
                        {title: "Numer dokumentu", field: "full_number"},
                        {title: "Realizacja", field: "state", lookup: { 0: 'Zaakceptowane', 1: 'W realizacji', 2: 'Zrealizowane' },},
                        {title: "Kontrahent", field: "contractor_name"},
                        {title: "Data przyjęcia", field: "acceptance_date_formatted"},
                        {title: "Data realizacji", field: "closing_date_formatted"},
                        {title: "Magazyn", field: "warehouse_symbol"},
                        {title: "Opis", field: "description"},
                    ]}
                    // options={{
                    //     actionsColumnIndex: -1,
                    //     pageSize: 15,
                    // }}
                    onRowClick={handleClickOpen}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    title={tableTitle}
                    tableRef={tableRef}
                    actions={actions}
                />
            </Item>
        </div>
    )
}