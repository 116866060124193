import {API} from '../constants';
import axios from 'axios';

export const getUsers = async (filters) => {
    let url = `${API.USERS}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}


export const updateUser = async (uuid, body) => {
    return axios.patch(`${API.USERS}${uuid}/`, body);
}

export const createUser = async (body) => {
    return axios.post(`${API.USERS}`, body);
}

export const getAllUsers = async () => {
    return axios.get(`${API.USERS}all/`);
}

export const getAllOptimaUsers = async () => {
    return axios.get(`${API.OPTIMA_USERS}all/`);
}

export const getOptimaUsers = async (filters) => {
    let url = `${API.OPTIMA_USERS}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}