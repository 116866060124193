import React, {useState} from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Button, Dialog} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../common/TabPanel";
import OrderSummary from "./OrderSummary";
import OrderContractor from "./OrderContractor";
import {primary} from "../../theme";
import SaveIcon from '@material-ui/icons/Save';
import OrderDescription from "./OrderDescription";
import {Transition} from "../common/Transition";
import {DialogActions, DialogContent} from "@mui/material";
import OrderActivities from "./OrderActivities";
import OrderForm from "./OrderForm";
import OrderAttributes from "./OrderAttributes";
import OrderServiceParts from "./OrderServiceParts";
import OrderNotes from "./OrderNotes";
import serviceOrders from "./ServiceOrders/ServiceOrders";

export const OrderDialog = ({
                                open,
                                handleClose,
                                handleSave,
                                orderData,
                                contractorData,
                                setContractorData,
                                setTempOrderData,
                                tempOrderData,
                                defaultData,
                                summaryChanged,
                                setSummaryChanged,
                                contractorName,
                                setContractorName,
                                deviceName,
                                setDeviceName,
                                descriptionChanged,
                                setDescriptionChanged,
                                setOrderData,
                                value,
                                setValue,
                                notes,
                                stageName,
                                setStageName,
                                attributes,
                                contractorDisabled,
                                newForm = false,
                                disabled = false,
                                disabledTabs = false,
                                stageDisabled = false,
                            }) => {

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleCloseInternal = () => {
        handleClose()
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={handleCloseInternal}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            disableBackdropClick={true}
        >
            <AppBar sx={{position: "static", bgcolor: primary}}>
                <Toolbar>

                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        Zlecenie
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseInternal}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>


                <Tabs value={value} onChange={handleChange} variant="scrollable">

                    <Tab label={"Ogólne" + (summaryChanged ? " *" : "")} value={0} {...a11yProps(0)}/>
                    <Tab label="Kontrahent" value={1} {...a11yProps(1)}/>
                    <Tab label="Czynności" value={2} {...a11yProps(2)} disabled={disabledTabs}/>
                    <Tab label="Części" value={3} {...a11yProps(3)} disabled={disabledTabs}/>
                    <Tab label="Atrybuty" value={4} {...a11yProps(4)} disabled={disabledTabs}/>
                    <Tab label="Notatki" value={5} {...a11yProps(5)} disabled={disabledTabs}/>
                    <Tab label="Dokumenty" value={6} {...a11yProps(6)} disabled={disabledTabs}/>
                    <Tab label={"Opis" + (descriptionChanged ? " *" : "")} value={7} {...a11yProps(7)} />
                    <Tab label="Dane z formularza" value={8} {...a11yProps(8)}/>

                </Tabs>

            </AppBar>
            <DialogContent>

                <TabPanel value={value} index={0}>
                    <OrderSummary setContractorData={setContractorData} disabled={disabled}
                                  defaultData={defaultData} tempOrderData={tempOrderData}
                                  setTempOrderData={setTempOrderData} contractorName={contractorName}
                                  setContractorName={setContractorName} deviceName={deviceName}
                                  setDeviceName={setDeviceName} setSummaryChanged={setSummaryChanged}
                                  setOrderData={setOrderData} contractorDisabled={contractorDisabled}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OrderContractor contractorData={contractorData}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <OrderActivities serviceOrder={orderData.uuid} disabled={disabled}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <OrderServiceParts serviceParts={orderData.service_parts}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <OrderAttributes attributes={attributes} disabled={disabled}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <OrderNotes notes={notes} disabled={disabled} serviceOrder={orderData.uuid}/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <OrderDescription tempOrderData={tempOrderData} setTempOrderData={setTempOrderData}
                                      descriptionChanged={descriptionChanged}
                                      setDescriptionChanged={setDescriptionChanged} disabled={disabled}
                                      stageName={stageName} setStageName={setStageName} stageDisabled={stageDisabled}/>
                </TabPanel>

                <TabPanel value={value} index={8}>
                    <OrderForm tempOrderData={tempOrderData}
                               contractorName={contractorName}
                               deviceName={deviceName}/>
                </TabPanel>

            </DialogContent>
            {disabled ?
                <></> :
                <DialogActions>
                    <Button onClick={handleSave}>
                        <SaveIcon/> Zapisz zmiany
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};