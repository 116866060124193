import axios from "axios";
import {API} from "../constants";

export const getAllShipments = async () => {
    return axios.get(`${API.SHIPPING}all/`)
}
export const getAllShippingCompanies = async () => {
    return axios.get(`${API.SHIPPING_COMPANIES}all/`)
}

export const getAllShippingMethods = async () => {
    return axios.get(`${API.SHIPPING_METHODS}all/`)
}

export const getShipments = async () => {
    return axios.get(`${API.SHIPPING}`)
}

export const getAllStatuses = async () => {
    return axios.get(`${API.STATUSES}all/`)
}

export const updateStatus = async (uuid, data) => {
    return axios.patch(`${API.STATUSES}${uuid}/`, data)
}

export const getShipmentsCompleted = async () => {
    return axios.get(`${API.SHIPPING}completed/`)
}

export const getShipmentsAwaiting = async () => {
    return axios.get(`${API.SHIPPING}awaiting/`)
}

export const sendShipment = async (uuid) => {
    return axios.post(`${API.SHIPPING}${uuid}/send/`)
}

export const updateShippingAddress = async (uuid, data) => {
    return axios.patch(`${API.SHIPPING_ADDRESS}${uuid}/`, data)
}

export const updateShipping = async (uuid, data) => {
    return axios.patch(`${API.SHIPPING}${uuid}/`, data)
}

export const downloadShippingLabel = async (uuid) => {
    axios({
        url: `${API.SHIPPING}${uuid}/label/`,
        method: "GET",
        responseType: "blob",
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `Etykieta.pdf`
        );
        document.body.appendChild(link);
        link.click();
    });
}