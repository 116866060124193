import React from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {styled} from "@mui/material";
import {Paper} from "@material-ui/core";
import {primary} from "../../theme";

export function UserButtons({handleButtonClick}) {
    const Item = styled(Paper)(() => ({
        backgroundImage: null,
        // padding: spacing(1),
        textAlign: 'center',
    }));

    return (
        <Item elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Button fullWidth size='large' variant="contained"
                            style={{'border-radius': 0, 'backgroundColor': primary, "color": "black"}}
                            onClick={handleButtonClick}>Nowy użytkownik</Button>
                </Grid>

            </Grid>
        </Item>
    );
};