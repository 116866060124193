import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Item} from "../common/styles";
import {getAllDocumentTypes, updateDocumentTypes} from "../../services/documentService";
import {getAllWarehouses} from "../../services/warehouseService";
import SimpleTable from "../common/SimpleTable";
import {getAllDevices, getDevice, updateDevices} from "../../services/deviceService";
import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import {
    getActiveAttributesDefinition,
    getAllOrderTypes,
    getAllStages, getStage,
    updateOrderType, updateStage
} from "../../services/serviceOrdersService";
import {
    getAllShippingCompanies,
    getAllShippingMethods,
    getAllStatuses,
    updateStatus
} from "../../services/shippingService";
import {UserDialog} from "../Users/UserDialog";
import {StagesDialog} from "./StagesDialog";
import CustomTable from "../common/CustomTable";
import {DeviceDialog} from "./DeviceDialog";


function Connections() {
    const [warehousesLookup, setWarehousesLookup] = useState({});
    const [orderTypes, setOrderTypes] = useState([]);
    const [documentsLookup, setDocumentsLookup] = useState({});
    const [shippingCompanyLookup, setShippingCompanyLookup] = useState({});
    const [shippingMethodLookup, setShippingMethodLookup] = useState({});
    const [attributesLookup, setAttributesLookup] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [stages, setStages] = useState([]);
    const [isStagesOpen, setIsOpenStages] = useState(false);
    const [isDeviceOpen, setIsOpenDevice] = useState(false);
    const [stagesData, setStagesData] = useState({});
    const [deviceData, setDeviceData] = useState({});
    const [attrNames, setAttrName] = React.useState([]);
    const [methodNames, setMethodsName] = React.useState([]);
    const devicesTableRef = React.useRef();
    const documentsTableRef = React.useRef();
    const statusTableRef = React.useRef();
    const orderTypesTableRef = React.useRef();
    const stagesTableRef = React.useRef();


    const renderAttrs = (rowData) => {
        let newArray = [];
        rowData.attributes.forEach((item) => {
            newArray.push(attributesLookup[item])
        })
        return newArray.join(', ')
    }
    const renderMethods = (rowData) => {
        let newArray = [];
        rowData.shipping_method.forEach((item) => {
            newArray.push(shippingMethodLookup[item])
        })
        return newArray.join(', ')
    }

    const handleStagesOpen = (event, data) => {
        setIsOpenStages(true);
        let attrs = [];
        data.attributes.forEach((item) => {
            attrs.push(attributesLookup[item])
        })
        setAttrName(attrs);
        setStagesData(data);
    }
    const handleDeviceOpen = (event, data) => {
        setIsOpenDevice(true);
        let methods = [];
        data.shipping_method.forEach((item) => {
            methods.push(shippingMethodLookup[item])
        })
        setMethodsName(methods);
        setDeviceData(data);
    }

    const handleStageClose = () => {
        setIsOpenStages(false);
        setStagesData({});
    }

    const handleDeviceClose = () => {
        setIsOpenDevice(false);
        setDeviceData({});
    }

    const handleStageSave = (uuid) => {
        updateStage(stagesData.uuid, {'attributes': uuid}).then((r) => {
            stagesTableRef.current.onQueryChange();
            handleStageClose();
        });
    }

    const handleDeviceSave = (uuid) => {
        updateDevices(deviceData.uuid, {'shipping_method': uuid}).then(r => {
                devicesTableRef.current.onQueryChange();
                handleDeviceClose();
            }
        )

    }

    const getWarehousesLookup = async () => {
        const warehouses = await getAllWarehouses();
        let warehousesDict = {};
        warehouses.data.forEach((item) => {
            warehousesDict[item.uuid] = item.name
        });
        warehousesDict[null] = '---------';
        return warehousesDict
    }

    const getAllDocumentLookup = async (data) => {
        let documentsLookup = {};
        data.forEach((item) => {
            documentsLookup[item.uuid] = item.name
        });
        documentsLookup[null] = '---------';
        return documentsLookup
    }
    const getShippingCompanyLookup = async () => {
        let shippingCompanies = await getAllShippingCompanies();
        let shippingCompanyDict = {};
        shippingCompanies.data.forEach((item) => {
            shippingCompanyDict[item.uuid] = item.name
        });
        shippingCompanyDict[null] = '---------';
        return shippingCompanyDict
    }

    const getShippingMethodLookup = async () => {
        let shippingMethods = await getAllShippingMethods();
        let shippingMethodsDict = {};
        shippingMethods.data.forEach((item) => {
            shippingMethodsDict[item.uuid] = item.name
        });
        shippingMethodsDict[null] = '---------';
        return shippingMethodsDict
    }

    const getAllAttributesLookup = async () => {
        const attributes = await getActiveAttributesDefinition();
        let attributesLookup = {};
        attributes.data.forEach((item) => {
            attributesLookup[item.uuid] = item.code
        });
        // attributesLookup[null] = '---------';
        return attributesLookup
    }

    const getDocumentTypes = async () => {
        getAllDocumentTypes().then(result => {
            setDocumentTypes(result.data);
            getAllDocumentLookup(result.data).then(r => setDocumentsLookup(r));
        });
    }

    const getDevices = async () => {
        getAllDevices().then(result => setDevices(result.data))
    }

    const getStatuses = async () => {
        getAllStatuses().then(result => setStatuses(result.data))
    }
    const getOrderTypes = async () => {
        getAllOrderTypes().then(result => setOrderTypes(result.data))
    }

    const getStages = async () => {
        getAllStages().then(result => setStages(result.data))
    }

    useEffect(() => {
        getDocumentTypes().then(r => {
        });
        getWarehousesLookup().then(result => {
            setWarehousesLookup(result)
        });
        getAllAttributesLookup().then(result => {
            setAttributesLookup(result)
        })
        getShippingCompanyLookup().then(result => {
            setShippingCompanyLookup(result);
        })
        getShippingMethodLookup().then(result => {
            setShippingMethodLookup(result);
        })
        getDevices().then(r => {
        });
        getStatuses().then(r => {
        });
        getOrderTypes().then(r => {

        });
        getStages().then(r => {

        })


    }, []);
    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Powiązania
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={6}>*/}
                        {/*    <Item elevation={0}>*/}
                        {/*        <SimpleTable*/}
                        {/*            tableRef={documentsTableRef}*/}
                        {/*            title={'Powiązania typ dokumentu - magazyn'}*/}
                        {/*            data={documentTypes}*/}
                        {/*            columns={[*/}
                        {/*                {title: "Nazwa", field: "name", editable: 'never'},*/}
                        {/*                {title: "Symbol", field: "symbol", editable: 'never'},*/}
                        {/*                {*/}
                        {/*                    title: "Aktywny",*/}
                        {/*                    field: "active",*/}
                        {/*                    editable: 'never',*/}
                        {/*                    lookup: {false: 'Niekatywny', true: 'Aktywny'}*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    title: "Magazyn",*/}
                        {/*                    field: "warehouse",*/}
                        {/*                    lookup: warehousesLookup,*/}
                        {/*                },*/}

                        {/*            ]}*/}
                        {/*            // options={{pageSize: 10}}*/}
                        {/*            editable={{*/}
                        {/*                onRowUpdate: (newData, oldData) =>*/}
                        {/*                    new Promise((resolve, reject) => {*/}
                        {/*                        setTimeout(() => {*/}
                        {/*                            updateDocumentTypes(newData.uuid, {'warehouse': newData.warehouse}).then(r => getDocumentTypes())*/}
                        {/*                            resolve();*/}
                        {/*                        }, 1500)*/}
                        {/*                    }),*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </Item>*/}
                        {/*</Grid>*/}
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={devicesTableRef}
                                    title={'Powiązania urządzenie - metody pakowania'}
                                    getData={getDevice}
                                    columns={[
                                        {title: "Kod", field: "code", editable: 'never'},
                                        {title: "Nazwa", field: "name", editable: 'never'},
                                        {
                                            title: "Metody pakowania",
                                            field: "shipping_method",
                                            render: rowData => renderMethods(rowData)
                                        },
                                    ]}
                                    onRowClick={handleDeviceOpen}
                                    // options={{pageSize: 10}}
                                    // editable={{
                                    //     onRowUpdate: (newData, oldData) =>
                                    //         new Promise((resolve, reject) => {
                                    //             setTimeout(() => {
                                    //                 updateDevices(newData.uuid, {'shipping_company': newData.shipping_company}).then(r => getDevices())
                                    //                 resolve();
                                    //             }, 1500)
                                    //         }),
                                    // }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <SimpleTable
                                    tableRef={statusTableRef}
                                    title={'Powiązania status wysyłki - atrybut'}
                                    data={statuses}
                                    columns={[
                                        {title: "Kod", field: "code", editable: 'never'},
                                        {title: "Nazwa", field: "name", editable: 'never'},
                                        {
                                            title: "Atrybut",
                                            field: "attribute",
                                            lookup: attributesLookup,
                                        },
                                    ]}
                                    // options={{pageSize: 10}}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {

                                                    updateStatus(newData.uuid, {'attribute': newData.attribute}).then(r => getStatuses())
                                                    resolve();
                                                }, 1500)
                                            }),
                                    }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <SimpleTable
                                    tableRef={orderTypesTableRef}
                                    title={'Powiązania magazyn - typ zgłoszenia'}
                                    data={orderTypes}
                                    columns={[
                                        {title: "Nazwa", field: "name", editable: 'never'},
                                        {
                                            title: "Magazyn",
                                            field: "warehouse",
                                            lookup: warehousesLookup,
                                        },

                                    ]}
                                    // options={{pageSize: 10}}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    updateOrderType(newData.uuid, {'warehouse': newData.warehouse}).then(r => getOrderTypes())
                                                    resolve();
                                                }, 1500)
                                            }),
                                    }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={stagesTableRef}
                                    title={'Powiązania etap - atrybut'}
                                    getData={getStage}
                                    columns={[
                                        {title: "Kod", field: "code", editable: 'never'},
                                        {
                                            title: "Atrybut",
                                            field: "attribute",
                                            render: rowData => renderAttrs(rowData)
                                        },

                                    ]}
                                    onRowClick={handleStagesOpen}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <StagesDialog
                    handleClose={handleStageClose}
                    stagesData={stagesData}
                    open={isStagesOpen}
                    handleSave={handleStageSave}
                    attrNames={attrNames}
                    setAttrName={setAttrName}
                />
                <DeviceDialog
                    handleClose={handleDeviceClose}
                    deviceData={deviceData}
                    open={isDeviceOpen}
                    handleSave={handleDeviceSave}
                    attrNames={methodNames}
                    setAttrName={setMethodsName}
                />
            </Box>

        </div>
    )
}

export default Connections;