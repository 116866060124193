import React, {useState} from "react";
import {Item} from "../common/styles";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import {
    createEmailTemplate,
    getEmailTemplates,
    updateEmailTemplate,
    getAllEmailTemplates
} from "../../services/settingService";
import CustomTable from "../common/CustomTable";
import {getEmailSent, getStage, updateStage} from "../../services/serviceOrdersService";
import {EmailTemplateDialog} from "./EmailTemplateDialog";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {EmailSentDialog} from "./EmailSentDialog";


function Mailing() {
    const stagesTableRef = React.useRef();
    const templatesTableRef = React.useRef();
    const [isAddTemplateOpen, setAddTemplateOpen] = useState(false);
    const [isEmailSentDialogOpen, setEmailSentDialogOpen] = useState(false);
    const [newTemplateData, setNewTemplateData] = useState({});
    const [newTemplateDataChanged, setNewTemplateDataChanged] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [emailSentData, setEmailSentData] = useState({});

    const closeEmailDialog = () => {
        setNewTemplateData({});
        setNewTemplateDataChanged(false);
        setAddTemplateOpen(false);
        setCurrentData({});
    }
    const closeEmailSentDialog = () => {
        setEmailSentData({});
        setEmailSentDialogOpen(false);

    }

    const handleTemplateSave = () => {
        if (newTemplateDataChanged) {
            if (currentData.uuid) {
                updateEmailTemplate(currentData.uuid, newTemplateData).then(() => {
                    templatesTableRef.current.onQueryChange();
                    closeEmailDialog();
                })
            } else {
                createEmailTemplate(newTemplateData).then(() => {
                    templatesTableRef.current.onQueryChange();
                    closeEmailDialog();
                })
            }
        }
    }

    const prepareData = (newData, oldData) => {
        let data = {}
        if (oldData) {
            Object.keys(newData).forEach((key) => {
                if (newData[key] !== oldData[key]) {
                    data[key] = newData[key];
                }
            })

            return data
        }

    }

    const handleClickEditTemplate = (data) => {
        setCurrentData(data);
        setAddTemplateOpen(true);
    }

    const handleClickShowEmailSent = (data) => {
        setEmailSentData(data);
        setEmailSentDialogOpen(true);
    }
    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={templatesTableRef}
                                    title={'Wzory Email'}
                                    getData={getEmailTemplates}
                                    columns={[
                                        {title: "Nazwa", field: "name", editable: 'never'},
                                        {
                                            title: "Tytuł",
                                            field: "subject",
                                            editable: 'never',
                                        },
                                    ]}
                                    onRowClick={(e, data) => handleClickEditTemplate(data)}
                                    actions={[
                                        {
                                            icon: AddIcon,
                                            tooltip: 'Dodaj wzór',
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                setAddTemplateOpen(true)
                                            }
                                        }
                                    ]}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={stagesTableRef}
                                    title={'Etapy - wzory email'}
                                    getData={getStage}
                                    columns={[
                                        {title: "Kod", field: "code", editable: 'never'},
                                        {
                                            title: "Email",
                                            field: "email_template",
                                            render: rowData => rowData.email_template ? rowData.email_template.name : '',
                                            editComponent: props => (
                                                <AsyncAutocomplete getData={getAllEmailTemplates}
                                                                   defaultValue={props.value}
                                                                   onChange={(e, value) => {props.onChange(e.uuid);} }
                                                                   variant={"standard"}
                                                                   optionLabel={(option) => option.name}/>
                                            )
                                        },

                                    ]}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    let data = prepareData(newData, oldData);
                                                    updateStage(oldData.uuid, data).then(() => {
                                                            stagesTableRef.current.onQueryChange();
                                                        }
                                                    )
                                                    resolve();
                                                }, 1000);
                                            }),
                                    }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item elevation={0}>
                                <CustomTable
                                    title={'Wysłane maile'}
                                    getData={getEmailSent}
                                    columns={[
                                        {title: "Data", field: "date_of_sent", editable: 'never'},
                                        {
                                            title: "Tytuł",
                                            field: "subject",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Etap",
                                            field: "stage",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Zlecenie",
                                            field: "service_order",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Email",
                                            field: "email",
                                            editable: 'never',
                                        },
                                        {
                                            title: "Czy wyslano",
                                            field: "sent",
                                            lookup: {true: 'Tak', false: 'Nie'},
                                        },
                                    ]}
                                    onRowClick={(e, data) => handleClickShowEmailSent(data)}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <EmailTemplateDialog open={isAddTemplateOpen}
                                 data={currentData}
                                 disabled={false}
                                 handleClose={closeEmailDialog}
                                 newTemplateData={newTemplateData}
                                 setNewTemplateData={setNewTemplateData}
                                 setDataChanged={setNewTemplateDataChanged}
                                 dataChanged={newTemplateDataChanged}
                                 handleSave={handleTemplateSave}
            />
            <EmailSentDialog open={isEmailSentDialogOpen} data={emailSentData} handleClose={closeEmailSentDialog}/>
        </div>
    )
}

export default Mailing;