import React, {useEffect, useState} from "react";
import {Grid, TextField} from '@material-ui/core';
import CustomDialogSave from "../common/CustomDialogSave";
import {OutlinedInput} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";
import {getActiveAttributesDefinition} from "../../services/serviceOrdersService";

export const StagesDialog = ({
                                 open,
                                 handleClose,
                                 stagesData,
                                 handleSave,
                                 attrNames,
                                 setAttrName
                             }) => {
    const [attributes, setAttributes] = useState([]);

    const [attributesDict, setAttributesDict] = React.useState({});

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setAttrName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const handleSaveInternal = () => {
        let uuid = [];
        attrNames.forEach((item)=> {
            uuid.push(attributesDict[item])
        })
        setAttrName([]);
        handleSave(uuid);
    }

    const handleCloseInternal = () => {
        handleClose()
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const getAllAttributesLookup = async () => {
        const attributes = await getActiveAttributesDefinition();
        let attributesLookup = [];
        let attributesDictTemp = {};
        attributes.data.forEach((item) => {
            attributesLookup.push(item);
            attributesDictTemp[item.code] = item.uuid
        });
        setAttributesDict(attributesDictTemp);
        return attributesLookup
    }

    useEffect(() => {
        getAllAttributesLookup().then(r => setAttributes(r));
    }, []);

    return (
        <CustomDialogSave open={open} handleClose={handleCloseInternal} maxWidth={"sm"} title={'Etap'}
                          handleSave={handleSaveInternal}>
            <Grid item xs={12}>
                <TextField id="outlined-basic" label="Etap" variant="outlined" fullWidth
                           defaultValue={stagesData.description} disabled={true}/>
            </Grid>
            <Grid item xs={3}>
                Atrybuty
            </Grid>
            <Grid item xs={9}>
                <Select
                    id="demo-multiple-chip"
                    multiple
                    value={attrNames}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {attributes.map((item) => (
                        <MenuItem
                            key={item.uuid}
                            value={item.code}
                        >
                            {item.code}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

        </CustomDialogSave>
    )
};