
import axios from "axios";
import {API} from "../constants";

export const getCategories = async (filters) => {
    let url = `${API.CATEGORIES}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url)
}

export const getAllCategories = async () => {
    return axios.get(`${API.CATEGORIES}all/`)
}