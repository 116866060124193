import {API} from '../constants';
import axios from 'axios';

export const getCustomers = async (filters) => {
    let url = `${API.CUSTOMERS}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}

export const getAllCustomers = async () => {
    return axios.get(`${API.CUSTOMERS}all/`)
}

export const getCustomersUnconfirmed = () => {
    return axios.get(`${API.CUSTOMERS}unconfirmed/`)
}
export const getCustomersConfirmed = () => {
    return axios.get(`${API.CUSTOMERS}confirmed/`)
}

export const updateCustomer = async (uuid, body) => {
    return axios.patch(`${API.CUSTOMERS}${uuid}/`, body)
}