import React from "react";
import {Item} from "../common/styles";
import Box from "@mui/material/Box";
import {Grid} from "@material-ui/core";
import {getLogs} from "../../services/settingService";
import CustomTable from "../common/CustomTable";


function Logging() {
    const loggingTableRef = React.useRef();

    return (
        <div>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={0}>
                                <CustomTable
                                    tableRef={loggingTableRef}
                                    title={'Logi'}
                                    getData={getLogs}
                                    columns={[
                                        {title: "Numer", field: "number", editable: 'never'},
                                        {title: "Data", field: "created", editable: 'never'},
                                        {title: "Model", field: "model_name", editable: 'never'},
                                        {title: "Metoda", field: "method_name", editable: 'never'},
                                        {
                                            title: "Exception",
                                            field: "exception_traceback",
                                            editable: 'never',
                                        },
                                    ]}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default Logging;