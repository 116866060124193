import CustomersStack from "../CustomersStack";
import {getCustomersConfirmed} from "../../../services/customerService";

function Customers() {
    return (
        <CustomersStack getData={getCustomersConfirmed} title={'Klienci'} disabled={true}/>
    )

}

export default Customers;