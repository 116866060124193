import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function AsyncAutocomplete(props) {
  const { getData, label, defaultValue, optionLabel, disabled, onChange, name, variant, size } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await getData();
      const data = await response.data;

      if (active) {
        setOptions(Object.keys(data).map((key) => data[key]));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {

    if (!open) {
      // setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-autocomplete"
      title={label}
      // style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
        onChange={(event, newValue) => {onChange(newValue, name)}}
        // onChange={onChange}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={optionLabel}
      options={options}
      loading={loading}
      size={size}
      disabled={disabled}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          title={label}
          variant={variant ? variant : "outlined"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
