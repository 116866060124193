import React, {useState} from "react";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Button,
    Dialog,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import {primary} from '../../theme';
import {Checkbox, DialogActions, Divider, FormControlLabel} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import SaveIcon from "@material-ui/icons/Save";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {getAllProductsService} from "../../services/productService";
import {getAllCategories} from "../../services/categoryService";
import {getTaxes} from "../../services/settingService";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ActivityDialog = ({
                                   open,
                                   handleClose,
                                   newData,
                                   setNewData,
                                   dataChanged,
                                   setDataChanged,
                                   handleSave,
                                   data,
                                   setData,
                                   marginValue,
                                   setMarginValue,
                                   marginPercent,
                                   setMarginPercent
                               }) => {


        const [marginKey, setMarginKey] = useState(0);
        const [refreshKey, setRefreshKey] = useState(0);

        const onValueChange = (key, value) => {
            setDataChanged(true);
            if (key === 'price_net' && data.quantity) {
                let newDict = {};
                let value_net = data.quantity * value;
                if (data.tax_percentage && data.tax_percentage.value) {
                    let price_gross = value * (100 + parseFloat(data.tax_percentage.value)) / 100;
                    let value_gross = price_gross * data.quantity;
                    newDict = {'value_gross': value_gross.toFixed(2), 'price_gross': price_gross.toFixed(2)}
                }
                if (data.service_cost) {
                    let marg = value_net - data.service_cost;
                    let margPer = (marg * 100) / value_net;
                    setMarginValue(marg.toFixed(2));
                    setMarginPercent(margPer.toFixed(2));
                    setMarginKey(marginKey + 1);
                }

                setData({...data, ...newDict, [key]: value, 'value_net': value_net.toFixed(2)})


            } else if (key === 'quantity' && data.price_net) {
                let newDict = {};
                let value_net = data.price_net * value;
                if (data.price_gross) {
                    let value_gross = data.price_gross * value;
                    newDict = {'value_gross': value_gross.toFixed(2)}
                }
                if (data.service_cost){
                    let marg = value_net - data.service_cost;
                    let margPer = (marg * 100) / value_net;
                    setMarginValue(marg.toFixed(2));
                    setMarginPercent(margPer.toFixed(2));
                    setMarginKey(marginKey + 1);
                }

                setData({...data, ...newDict, [key]: value, 'value_net': value_net.toFixed(2)})

            } else if (key === 'tax_percentage' && data.price_net) {
                let newDict = {};
                let price_gross = data.price_net * value;
                if (data.quantity) {
                    let value_gross = price_gross * data.quantity;
                    newDict = {'value_gross': value_gross.toFixed(2)};
                }
                setData({...data, ...newDict, [key]: value, 'price_gross': price_gross.toFixed(2)})
            } else if (key === 'product') {
                setData({...data, [key]: value, 'product_name': value.name, 'unit': value.unit})
            } else if (key === 'service_cost' && data.value_net) {
                let marg = data.value_net - value;
                let margPer = (marg * 100) / data.value_net;
                setMarginValue(marg.toFixed(2));
                setMarginPercent(margPer.toFixed(2));
                setData({...data, [key]: value});
                setMarginKey(marginKey + 1)
            } else {
                setData({...data, [key]: value})
            }
            setRefreshKey(refreshKey + 1);
            // setNewData({...newData, [key]: value});
        }


        // const calculateDiscount = (discount) => {
        //     if (discount && netPrice) {
        //         let value = netPrice - (netPrice * discount / 100);
        //         setNetPrice(value.toFixed(2));
        //     }
        // }


        const clear = () => {

            setMarginKey(0);

        }
        const handleSaveInternal = () => {
            setNewData(data);
            clear();
            handleSave();
        }
        const handleInternalClose = () => {
            clear();
            handleClose();
        }

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={handleInternalClose}
                TransitionComponent={Transition}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <AppBar sx={{position: 'relative', bgcolor: primary}}>
                    <Toolbar>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {'Czynności'}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleInternalClose}
                            aria-label="close"
                            style={{postion: 'absoulte'}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{padding: '40px'}}>
                    <Box sx={{paddingBottom: "20px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.to_invoicing}
                                            name="to_invoicing"
                                            color="primary"
                                            onChange={(e) => {
                                                onValueChange('to_invoicing', !data.to_invoicing);

                                            }}
                                        />
                                    }
                                    label="Uwzględnij na FA/SP"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={data.is_finished}
                                            name="is_finished"
                                            color="primary"
                                            onChange={(e) => {
                                                onValueChange('is_finished', !data.is_finished)
                                            }}
                                        />
                                    }
                                    label="Zakończona"
                                />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={4}>
                                <AsyncAutocomplete getData={getAllProductsService}
                                                   label={'Usługa'}
                                                   defaultValue={data.product}
                                                   variant={"outlined"}
                                                   onChange={(e) => {
                                                       onValueChange('product', e ? e : null)
                                                   }}
                                                   optionLabel={(option) => option.name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label="Nazwa towaru"
                                           variant="outlined"
                                           fullWidth
                                           disabled={true}
                                           defaultValue={data.product_name}
                                           key={refreshKey}
                                />
                            </Grid>
                            {/*<Grid item xs={4}>*/}
                            {/*    <AsyncAutocomplete getData={getAllCategories}*/}
                            {/*                       label={'Kategoria'}*/}
                            {/*                       variant={"outlined"}*/}
                            {/*                       optionLabel={(option) => option.description}*/}
                            {/*                       onChange={(e) => {*/}
                            {/*                           onValueChange('category', e ? e.uuid : null)*/}
                            {/*                       }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                                    <DatePicker label={'Data wykonania'}
                                                defaultValue={dayjs(data.date_of_service)}
                                                fullWidth
                                                format='YYYY-MM-DD'
                                                onChange={(e) => {
                                                    onValueChange('date_of_service', e.format('YYYY-MM-DD'))
                                                }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                                    <DatePicker label={'Czas trwania'}
                                                fullWidth
                                                format='YYYY-MM-DD'
                                                disabled={true}
                                                onChange={(e) => {
                                                    onValueChange('date_of_service', e.format('YYYY-MM-DD'))
                                                }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {/*<Grid item xs={6}></Grid>*/}
                            <Grid item xs={6}>
                                <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                    Wycena
                                    <Divider color={'black'} fullWidth/>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                    Koszt
                                    <Divider color={'black'} fullWidth/>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           label='Ilość'
                                           type={"number"}
                                           defaultValue={data.quantity}
                                           onChange={(e) => {
                                               onValueChange('quantity', e.target.value);
                                           }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           label='Jednostka'
                                           value={data.unit}
                                           key={refreshKey}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           type={"number"}
                                           label='Koszt'
                                           defaultValue={data.service_cost}
                                           onChange={e => {
                                               onValueChange('service_cost', e.target.value);
                                           }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           type={"number"}
                                           defaultValue={data.value_net}
                                           key={refreshKey}
                                           label='Wartoś netto '/>
                            </Grid>
                            <Grid item xs={3}>
                                {/* TODO reveal field while discounts are ready */}
                                {/*<TextField id="outlined-basic"*/}
                                {/*           variant="outlined"*/}
                                {/*           size="small"*/}
                                {/*           type={"number"}*/}
                                {/*           fullWidth*/}
                                {/*           label='Rabat %'*/}
                                {/*           onChange={(e) => {*/}

                                {/*               calculateDiscount(e.target.value);*/}
                                {/*               onValueChange('price_discount', e.target.value);*/}
                                {/*           }}*/}
                                {/*/>*/}
                            </Grid>
                            <Grid item xs={3}>
                                <AsyncAutocomplete getData={getTaxes}
                                                   size="small"
                                                   label={'Stawka VAT'}
                                                   defaultValue={data.tax_percentage}
                                                   onChange={(e) => {

                                                       onValueChange('tax_percentage', e ? e : '');
                                                   }}
                                                   optionLabel={(option) => option.name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           type={"number"}
                                           label='Marża'
                                           defaultValue={marginValue}
                                           key={marginKey}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           type={"number"}
                                           label='Marża %'
                                           defaultValue={marginPercent}
                                           key={marginKey}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           label='Cena netto'
                                           defaultValue={data.price_net}
                                           type={"number"}
                                           onChange={(e) => {
                                               onValueChange('price_net', e.target.value);
                                           }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           label='Wartość netto'
                                           type={"number"}
                                           defaultValue={data.value_net}
                                           key={refreshKey}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           label='Cena brutto'
                                           type={"number"}
                                           key={refreshKey}
                                           defaultValue={data.price_gross}
                                           disabled={true}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField id="outlined-basic"
                                           variant="outlined"
                                           size="small"
                                           fullWidth
                                           disabled={true}
                                           label='Wartość brutto'
                                           type={"number"}
                                           key={refreshKey}
                                           defaultValue={data.value_gross}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
                <DialogActions>
                    <Button onClick={handleSaveInternal} color="primary">
                        <SaveIcon/> Zapisz
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
;