import React, {useState} from "react";
import Stack from '@mui/material/Stack';
import {CustomersTable} from "./CustomersTable";
import {CustomerDialog} from "./CustomerDialog";
import CustomConfirmDialog from "../common/CustomConfirmDialog";
import {updateCustomer} from "../../services/customerService";
import SaveIcon from "@material-ui/icons/Save";
import CustomAlert from "../common/CustomAlert";


function CustomersStack({getData, title, disabled, dataToUpdate}) {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [customerData, setCustomerData] = useState({});
    const [newCustomerData, setNewCustomerData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const tableRef = React.useRef();


    const handleAccept = (event, rowData) => {
        const requiredFields = ['tax_number', 'street', 'state', 'postal_code', 'phone_number', 'name', 'country', 'city']
        let isRequiredFilled = true;
        Object.keys(rowData).forEach((key) => {
            if (requiredFields.includes(key)) {
                let val = rowData[key];
                if (val === null || val === '-' || val === '') {
                    isRequiredFilled = false;
                }
            }
        })
        if (isRequiredFilled) {
            updateCustomer(rowData.uuid, {'confirmed': true}).then(() => {
                setIsSuccess("success");
                setSuccessMessage('Zaakceptowano ' + rowData.name + '.');
                setIsOpenSuccess(true);
                tableRef.current.onQueryChange();
            })
        } else {
            setIsSuccess("error");
            setSuccessMessage('Dane firmy niekompletne.');
            setIsOpenSuccess(true);
        }
    }

    const actions =
        [{
            icon: SaveIcon,
            tooltip: 'Zaakceptuj',
            onClick: (event, rowData) => {
                handleAccept(event, rowData)
            }
        }]

    const handleClose = () => {
        setConfirmationOpen(false);
        setNewCustomerData({});
        setDataChanged(false);
        setIsOpenDialog(false);
    }

    const handleCloseCheck = () => {
        if (dataChanged === false) {
            handleClose()
        } else {
            setIsOpenDialog(false);
            setConfirmationOpen(true);
        }
    }

    const handleClickOpen = (event, data) => {
        setIsOpenDialog(true);
        setCustomerData(data);
        setNewCustomerData(data);
    }

    const prepareData = () => {
        let updatedData = {};
        dataToUpdate.forEach((key) => {
            if (newCustomerData[key] !== customerData[key]) {
                updatedData[key] = newCustomerData[key];
            }
        })
        return updatedData
    }


    const updateCustomerData = async () => {
        let data = prepareData();
        if (data) {
            updateCustomer(customerData.uuid, data).then((response) => {
                if (response.status === 200) {
                    setIsSuccess("success");
                    setSuccessMessage(response.statusText);
                } else {
                    setIsSuccess("error");
                    setSuccessMessage(response.statusText);
                }
                setIsOpenSuccess(true);
                tableRef.current.onQueryChange();
            });
        }
    }

    const handleSave = () => {
        updateCustomerData().then(() => {
            handleClose()
        })
    }

    const onGoBack = () => {
        setIsOpenDialog(true);
        setConfirmationOpen(false);
    }

    return (
        <div>
            <Stack spacing={2}>
                <CustomersTable
                    handleClickOpen={handleClickOpen}
                    tableTitle={title}
                    getData={getData}
                    tableRef={tableRef}
                    actions={disabled ? [] : actions}
                />
            </Stack>
            <CustomerDialog
                handleClose={handleCloseCheck}
                customerData={customerData}
                tempCustomerData={newCustomerData}
                setTempCustomerData={setNewCustomerData}
                open={isOpenDialog}
                disabled={disabled}
                handleSave={handleSave}
                dataChanged={dataChanged}
                setDataChanged={setDataChanged}
            />
            <CustomConfirmDialog handleClose={handleClose} open={isConfirmationOpen} onGoBack={onGoBack}>
                <div><p>Masz <b>niezapisane</b> zmiany w kontrahencie.</p>
                    <p> Czy napewno chcesz opuścić formularz ?</p></div>
            </CustomConfirmDialog>
            <CustomAlert isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess}
                         setSuccessMessage={setSuccessMessage} isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
                {successMessage}
            </CustomAlert>

        </div>
    );
}

export default CustomersStack;