import React from "react";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Button, Dialog} from "@material-ui/core";
import {Transition} from "../common/Transition";

function CustomConfirmDialog({children, handleClose, open, onGoBack}) {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            TransitionComponent={Transition}
        >
            <DialogTitle id="alert-dialog-title">{"Niezapisane zmiany"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onGoBack} color="primary">
                    Wróć
                </Button>
                <Button onClick={handleClose} color="red" autoFocus>
                    Wyjdź
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomConfirmDialog;