import {Card, CardContent, FormControlLabel, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Grid, TextField} from "@material-ui/core";
import React from "react";

export const MailingSettings = ({settingsData, handleChangeData}) => {
    return (<Card>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                        Mailing
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={<Switch checked={settingsData.mailing} onChange={() => {
                            handleChangeData('mailing', !settingsData.mailing)
                        }} name="mailing-enabled"/>}
                        label="Włącz mailing do klientów"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField id="outlined-basic" label="E-mail administratora" variant="outlined"
                               fullWidth
                               defaultValue={settingsData.admin_email}
                               onChange={(e) => {
                                   handleChangeData('admin_email', e.target.value)
                               }}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>)
}