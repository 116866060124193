import React from "react";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Button,
    Dialog,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import {primary} from '../../theme';
import {DialogActions} from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const EmailSentDialog = ({
                                    open,
                                    handleClose,
                                    data,
                                }) => {


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <AppBar sx={{position: 'relative', bgcolor: primary}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {'Wysłana wiadomość'}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label="Etap" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.stage ? data.stage : ''}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Zlecenie" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.service_order ? data.service_order : ''}

                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Data wysłania" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.date_of_sent ? data.date_of_sent : ''}

                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Email" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.email ? data.email : ''}

                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Wysłana" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.sent ? data.sent : ''}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Temat" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.subject ? data.subject : ''}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Wiadomość" variant="outlined"
                                       fullWidth
                                       multiline
                                       disabled={true}
                                       rows={30}
                                       defaultValue={data && data.message ? data.message : ''}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    );
};