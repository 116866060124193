import React, {useEffect, useState} from "react";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
    Button,
    Dialog,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import {primary} from '../../theme';
import {DialogActions, Divider} from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";
import AsyncAutocomplete from "../common/AsyncAutocomplete";
import {getCountries} from "../../services/settingService";
import {getAllProducts} from "../../services/productService";
import {getAllShippingCompanies, getAllShippingMethods} from "../../services/shippingService";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ShipmentDialog = ({
                                   open,
                                   handleClose,
                                   disabled,
                                   newData,
                                   setNewData,
                                   dataChanged,
                                   setDataChanged,
                                   handleSave,
                                   data,
                                   trackingDisabled = true
                               }) => {


    const onValueChange = (key, value) => {
        setDataChanged(true);
        setNewData({...newData, [key]: value})
    }

    const openTracking = () => {
        let url = ''
        let base_url = data.shipping_company.track_url;
        if (data.track_ids.length === 1) {
            url = `${base_url}${data.track_ids[0]}`
        } else {
            let packages = ''
            data.track_ids.forEach((value) => {
                packages += `${value}%2C+`
            })
            url = `${base_url}${packages}`
        }
        window.open(url, "_blank");
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <AppBar sx={{position: 'relative', bgcolor: primary}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {'Wysyłka' + (dataChanged ? " *" : "")}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{postion: 'absoulte'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField label="Zlecenie" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data && data.service_order ? data.service_order.full_number : ''}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AsyncAutocomplete getData={getAllShippingCompanies}
                                               disabled={disabled}
                                               defaultValue={data.shipping_company}
                                               onChange={e => onValueChange('shipping_company', e.uuid)}
                                               variant={"outlined"}
                                               optionLabel={(option) => option.name}/>
                        </Grid>
                        <Grid item xs={2}>
                            <AsyncAutocomplete getData={getAllShippingMethods}
                                               disabled={disabled}
                                               defaultValue={data.shipping_method}
                                               onChange={e => onValueChange('shipping_method', e.uuid)}
                                               variant={"outlined"}
                                               optionLabel={(option) => option.name}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Numer transakcji" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data ? data.parcel_id : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Track ID" variant="outlined" fullWidth={true} disabled={true}
                                       defaultValue={data ? data.track_ids : ''}
                            />
                        </Grid>
                        <Grid item xs={10}>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" style={{backgroundColor: "#FFCB00"}} fullWidth={true}
                                    disabled={trackingDisabled}
                                    onClick={openTracking}
                            >
                                Śledzenie
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Adres do wysyłki
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="outlined-basic" label="Nazwa" variant="outlined"
                                       fullWidth
                                       onChange={(e) => {
                                           onValueChange('name', e.target.value)
                                       }}
                                       disabled={disabled}
                                       defaultValue={data && data.address ? data.address.name : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Ulica" variant="outlined"
                                       fullWidth
                                       onChange={(e) => {
                                           onValueChange('street', e.target.value)
                                       }}
                                       disabled={disabled}
                                       defaultValue={data && data.address ? data.address.street : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="outlined-basic" label="Nr mieszkania"
                                       variant="outlined"
                                       fullWidth
                                       onChange={(e) => {
                                           onValueChange('home_number', e.target.value)
                                       }}
                                       disabled={disabled}
                                       defaultValue={data && data.address ? data.address.home_number : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="outlined-basic" label="Kod pocztowy"
                                       variant="outlined"
                                       fullWidth
                                       onChange={(e) => {
                                           onValueChange('postal_code', e.target.value)
                                       }}
                                       disabled={disabled}
                                       defaultValue={data && data.address ? data.address.postal_code : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Miasto" variant="outlined"
                                       fullWidth
                                       onChange={(e) => {
                                           onValueChange('city', e.target.value)
                                       }}
                                       disabled={disabled}
                                       defaultValue={data && data.address ? data.address.city : ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AsyncAutocomplete getData={getCountries} label={'Kraj'}
                                               defaultValue={data && data.address ? data.address.country : ''}
                                               disabled={disabled}
                                               onChange={(e) => onValueChange('country', e.uuid)}
                                               optionLabel={(option) => option.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider color={'black'} fullWidth/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {disabled ?
                <></> :
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        <SaveIcon/> Zapisz zmiany
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};