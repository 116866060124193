import {API} from '../constants';
import axios from 'axios';

export const getEmailTemplates = async () => {
    return axios.get(`${API.EMAIL_TEMPLATES}`);
}
export const getAllEmailTemplates = async () => {
    return axios.get(`${API.EMAIL_TEMPLATES}all/`);
}

export const createEmailTemplate = async (data) => {
    return axios.post(`${API.EMAIL_TEMPLATES}`, data);
}
export const updateEmailTemplate = async (uuid, data) => {
    return axios.patch(`${API.EMAIL_TEMPLATES}${uuid}/`, data);
}

export const getGeneralSettings = async () => {
    return axios.get(`${API.GENERAL_SETTINGS}`);
}

export const updateGeneralSettings = async (uuid, data) => {
    return axios.patch(`${API.GENERAL_SETTINGS}${uuid}/`, data);
}

export const getLogs = async () => {
    return axios.get(`${API.LOGS}`);
}

export const getCountries = async () => {
    return axios.get(`${API.COUNTRIES}all/`)
}

export const getTaxes = async () => {
    return axios.get(`${API.TAXES}all/`)
}