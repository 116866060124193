const PREFIXES = {
    DOCKER: "http://172.16.0.17:8000",
    LOCAL: "http://127.0.0.1:8000",
    DOCKER_NEW: "https://api.wagner-polska.com.pl"
  };

  const PREFIX = PREFIXES.DOCKER_NEW + '/api/v1';


export const API = {
    AUTH: `${PREFIX}/token/`,
    REFRESH: `${PREFIX}/token/refresh/`,
    USERS: `${PREFIX}/users/`,
    OPTIMA_USERS: `${PREFIX}/optima-users/`,
    CUSTOMERS: `${PREFIX}/contractors/`,
    SERVICE_ORDERS: `${PREFIX}/service-orders/`,
    WAREHOUSES: `${PREFIX}/warehouses/`,
    DEVICES: `${PREFIX}/devices/`,
    CATEGORIES: `${PREFIX}/categories/`,
    DOCUMENTS_TYPES: `${PREFIX}/document-types/`,
    NOTES: `${PREFIX}/notes/`,
    STAGES: `${PREFIX}/stages/`,
    ATTRIBUTES: `${PREFIX}/attributes/`,
    ATTRIBUTES_DEFINITION: `${PREFIX}/attributes-definition/`,
    ATTRIBUTES_DEFINITION_ITEMS: `${PREFIX}/attributes-definition-items/`,
    PRODUCTS: `${PREFIX}/products/`,
    ACTIVITIES: `${PREFIX}/service-activities/`,
    EMAIL_TEMPLATES: `${PREFIX}/email-templates/`,
    GENERAL_SETTINGS: `${PREFIX}/general-settings/`,
    LOGS: `${PREFIX}/logs/`,
    EMAILS_SENT: `${PREFIX}/emails-sent/`,
    SHIPPING: `${PREFIX}/shipping/`,
    SHIPPING_ADDRESS: `${PREFIX}/shipping-addresses/`,
    SHIPPING_STATUSES: `${PREFIX}/shipping-statuses/`,
    STATUSES: `${PREFIX}/statuses/`,
    COUNTRIES: `${PREFIX}/countries/`,
    TAXES: `${PREFIX}/taxes/`,
    ORDER_TYPES: `${PREFIX}/order-types/`,
    SHIPPING_COMPANIES: `${PREFIX}/shipping-companies/`,
    SHIPPING_METHODS: `${PREFIX}/shipping-methods/`,
}
