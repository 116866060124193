import CustomersStack from "../CustomersStack";
import {getCustomersUnconfirmed} from "../../../services/customerService";

function NewCustomers() {
    const dataToUpdate = ['city', 'code', 'country', 'home_number', 'name', 'phone_number',
        'post', 'postal_code', 'state', 'street', 'street_number', 'tax_number'];

    return (
        <CustomersStack getData={getCustomersUnconfirmed}
                        title={'Nowi klienci'}
                        disabled={false}
                        dataToUpdate={dataToUpdate}
        />
    )

}

export default NewCustomers;