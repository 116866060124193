import React, {useEffect, useState} from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Login from './components/Login/Login';
import {primary, theme} from "./theme";
import {ThemeProvider} from "@mui/material";
import {getAuthenticated, getFirstName, getLastName} from "./services/authenticationService";
import Home from "./components/Home/Home";
import NewCustomers from "./components/Customers/NewCustomers/NewCustomers";
import Users from "./components/Users/Users";
import ServiceOrders from "./components/Service/ServiceOrders/ServiceOrders";
import AcceptedServiceOrders from "./components/Service/AcceptedServiceOrders/AcceptedServiceOrders";
import OngoingServiceOrders from "./components/Service/OngoingServiceOrders/OngoingServiceOrders";
import RejectedServiceOrders from "./components/Service/RejectedServiceOrders/RejectedServiceOrders";
import ClosedServiceOrders from "./components/Service/ClosedServiceOrders/ClosedServiceOrders";
import Mailing from "./components/Settings/Mailing";
import Connections from "./components/Settings/Connections";
import NavigationWithDrawer from "./components/Navigation/NavigationWithDrawer";
import {makeStyles} from "@material-ui/core/styles";
import GeneralSettings from "./components/Settings/GeneralSettings/GeneralSettings";
import Customers from "./components/Customers/Customers/Customers";
import Logging from "./components/Settings/Logging";
import ShipmentsCompleted from "./components/Shipments/ShipmentsCompleted";
import ShipmentsAwaiting from "./components/Shipments/ShipmentsAwaiting";

require("./global");
const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',

    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
        background: primary
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: primary,
        color: 'black',
    },
    content: {
        flexGrow: 1,
        padding: 13,
        maxWidth: `calc(100vw - ${drawerWidth}px)`,
        overflowX: 'hidden',
        minHeight: '100vh',
        background: 'white',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw'
        }
    },
    divider: {
        flexGrow: 1
    },
    navIcon: {
        color: 'black',
        height: '60px',
        borderRadius: '0px',
        transition: 'padding 0.5s',
        marginBottom: '2px',
        '&:hover': {
            paddingLeft: 30,
            background: 'white',
            color: 'black'
        }
    },
    navIconActive: {
        background: 'white',
        color: 'black'
    },
    toolbarLogo: {
        maxWidth: '100%'
    },
    logoContainer: {
        padding: 10,
        marginTop: 10,
        maxWidth: drawerWidth
    },
    nested: {
        color: 'black',
        height: '60px',
        borderRadius: '0px',
        transition: 'padding 0.5s',
        marginBottom: '2px',
        '&:hover': {
            paddingLeft: theme.spacing(5),
            background: 'white',
            color: 'black'
        },
        paddingLeft: theme.spacing(4),
    },
    nestedActive: {
        paddingLeft: theme.spacing(4),
        background: 'white',
        color: 'black'

    }
}));

function App() {
    const classes = useStyles();
    const [currentUser, setCurrentUser] = useState({});
    const [opened, setOpened] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const fetchCurrentUser = async () => {
        setCurrentUser({first_name: getFirstName(), last_name: getLastName()});
    };
    const handleOpen = (item) => {
        setOpened({[item.id]: !opened[item.id]});

    }
    const handleClickClose = () => {
        setOpened({});
    }
    useEffect(() => {
        setIsAuthenticated(getAuthenticated());
        fetchCurrentUser().then(() => {
        })
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    {!isAuthenticated ?
                        (<Login setIsAuthenticated={setIsAuthenticated}/>)
                        :
                        (<> <NavigationWithDrawer currentUser={currentUser} opened={opened} setOpened={setOpened}
                                                  handleOpen={handleOpen} handleClickClose={handleClickClose}/>
                            <main className={classes.content}>
                                <div className={classes.toolbar}/>
                                <Routes>
                                    <Route path='dashboard' element={<Home/>}/>
                                    <Route path='customers' element={<Customers/>}/>
                                    <Route path='new-customers' element={<NewCustomers/>}/>
                                    <Route path='new-orders' element={<ServiceOrders/>}/>
                                    <Route path='accepted-orders' element={<AcceptedServiceOrders/>}/>
                                    <Route path='ongoing-orders' element={<OngoingServiceOrders/>}/>
                                    <Route path='rejected-orders' element={<RejectedServiceOrders/>}/>
                                    <Route path='closed-orders' element={<ClosedServiceOrders/>}/>
                                    <Route path='users' element={<Users/>}/>
                                    <Route path='mailing' element={<Mailing/>}/>
                                    <Route path='connections' element={<Connections/>}/>
                                    <Route path='general-settings' element={<GeneralSettings/>}/>
                                    <Route path='logs' element={<Logging/>}/>
                                    <Route path='shipments-completed' element={<ShipmentsCompleted/>}/>
                                    <Route path='shipments-awaiting' element={<ShipmentsAwaiting/>}/>
                                </Routes>
                            </main>
                        </>)}
                </div>
            </ThemeProvider>
        </>
    );
}

export default App;
