import React from "react";
import {Alert, Snackbar} from "@mui/material";


function CustomAlert({children, isOpenSuccess, setIsOpenSuccess, setSuccessMessage, isSuccess, setIsSuccess}) {

    const handleCloseSuccess = () => {
        setIsOpenSuccess(false);
        setSuccessMessage('');
        setIsSuccess('');
    }

    return (
        <Snackbar open={isOpenSuccess} autoHideDuration={9000} onClose={handleCloseSuccess}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleCloseSuccess} severity={isSuccess} sx={{width: '100%'}}>
                {children}
            </Alert>
        </Snackbar>
    )
}

export default CustomAlert;