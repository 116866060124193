import Box from "@mui/material/Box";
import {Dialog, Grid, TextField} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {DialogContent, Divider} from "@mui/material";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import {primary} from "../../theme";
import {Transition} from "../common/Transition";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {downloadServicerOrderImages} from "../../services/serviceOrdersService";

function OrderForm({
                       tempOrderData,
                       contractorName,
                       deviceName,
                   }) {
    const [pdf, setPdf] = useState(tempOrderData.purchase_document_base64);
    const [documentType, setDocumentType] = useState(tempOrderData.purchase_document_type);
    const [pdfOpened, setPdfOpen] = useState(false);

    return (
        <>
            <Box sx={{padding: '40px'}}>
                <Box sx={{paddingBottom: "20px"}}>
                    <Grid container spacing={2}>
                        {/*<Grid item xs={4}>*/}
                        {/*    <TextField id="outlined-basic" label="Dokument" variant="outlined" fullWidth disabled*/}
                        {/*               value={tempOrderData.document_type}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={8}>*/}
                        {/*    <TextField id="outlined-basic" label="Numer" variant="outlined" fullWidth disabled*/}
                        {/*               value={tempOrderData.full_number}/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={8}>
                            <TextField id="outlined-basic" label="Typ zgłoszenia" variant="outlined" disabled fullWidth
                                       value={tempOrderData.order_type_name}/>
                        </Grid>
                        {tempOrderData.service_address ?
                            (<Grid item xs={4}>
                                <TextField id="outlined-basic" label="Dostawa własna" variant="outlined" disabled fullWidth
                                           value={tempOrderData.service_address.name}/>
                            </Grid> ) : <></>
                        }
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Kontrahent
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled fullWidth
                                       value={tempOrderData.contractor_d ? tempOrderData.contractor_d.code : ''}/>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled fullWidth
                                       value={contractorName}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Ulica" variant="outlined" disabled fullWidth
                                       value={tempOrderData.contractor_street}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField id="outlined-basic" label="Numer domu" variant="outlined" disabled fullWidth
                                       value={tempOrderData.contractor_street_number}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField id="outlined-basic" label="Numer mieszkania" variant="outlined" disabled
                                       fullWidth
                                       value={tempOrderData.contractor_home_number}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Miasto" variant="outlined" disabled fullWidth
                                       value={tempOrderData.contractor_city}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField id="outlined-basic" label="Kod pocztowy" variant="outlined" disabled fullWidth
                                       value={tempOrderData.contractor_postal_code}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Email" variant="outlined" disabled fullWidth

                                       defaultValue={tempOrderData.email}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Numer telefonu" variant="outlined" disabled
                                       fullWidth
                                       defaultValue={tempOrderData.phone_number}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Urządzenie
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Urządzenie" variant="outlined" disabled fullWidth
                                       value={tempOrderData.device_d ? tempOrderData.device_d.code : ''}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Nazwa" variant="outlined" disabled={true} fullWidth
                                       value={deviceName}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Numer seryjny" variant="outlined" disabled={true}
                                       fullWidth
                                       value={tempOrderData.serial_number}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Data zakupu" variant="outlined" disabled={true}
                                       fullWidth
                                       value={tempOrderData.purchase_date}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-basic" label="Numer dowodu zakupu" variant="outlined"
                                       disabled={true}
                                       fullWidth
                                       value={tempOrderData.purchase_document_number}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Button fullWidth size='large' variant="contained"
                                    disabled={tempOrderData.purchase_document_base64 ? false : true}
                                    style={{height: '100%'}}
                                    onClick={() => {
                                        setPdfOpen(true)
                                    }}>
                                Podglad PDF
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button fullWidth size='large' variant="contained"
                                    disabled={tempOrderData.form_files && tempOrderData.form_files.length > 0 ? false : true}
                                    style={{height: '100%'}}
                                    onClick={() => {
                                        downloadServicerOrderImages(tempOrderData.uuid)
                                    }}>
                                Pobierz zdjecia
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div">
                                Daty i czas realizacji
                                <Divider color={'black'} fullWidth/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" label="Data przyjęcia" variant="outlined" disabled
                                       fullWidth
                                       defaultValue={tempOrderData.acceptance_date_formatted}/>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={pdfOpened}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: "static", bgcolor: primary}}>
                    <Toolbar>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Dowód zakupu
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setPdfOpen(false);
                            }}
                            aria-label="close"
                            style={{postion: 'absoulte'}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{height: '900px'}}>
                    {
                        documentType === 'pdf' ? (<embed src={`data:application/pdf;base64,${pdf}`} height="100%"
                                                         width="100%"/>)
                            : (<img src={`data:image/${documentType};base64,${pdf}`} height="100%" width="100%"/>)
                    }

                    )
                </DialogContent>
            </Dialog>
        </>
    )
}

export default OrderForm;
