import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {logout} from '../../services/authenticationService';
import Clock from 'react-live-clock';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Logo from '../../static/images/logo-wagner.png';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import {getModelPermissions} from '../../services/authenticationService';
import NavItem from "./NavItem";
import {useStyles} from "../../App";
import {Hidden, useTheme} from "@mui/material";
import {redirect} from "react-router-dom";
import {useNavigate} from "react-router-dom";

export default function NavigationWithDrawer({
                                                 currentUser,
                                                 opened,
                                                 setOpened,
                                                 handleOpen,
                                                 handleClickClose,
                                             }) {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const perms = getModelPermissions();
    const permsMapping = {
        'Dashboard': {
            id: 1,
            label: 'Dashboard',
            link: '/dashboard',
            icon: <AiIcons.AiFillHome/>,
        },
        'AllCustomers': {
            id: 2,
            label: 'Klienci',
            collapsable: true,
            link: '/new-customers',
            icon: <FaIcons.FaCartPlus/>,
            children: {
                'NewCustomers': {
                    label: 'Nowi klienci',
                    link: '/new-customers',
                },
                'Customers': {
                    label: 'Klienci',
                    link: '/customers',
                },
            }
        },
        'Orders': {
            id: 3,
            collapsable: true,
            label: 'Zlecenia',
            icon: <IoIcons.IoIosDocument/>,
            open: false,
            children: {
                'ServiceOrders': {
                    label: 'Nowe zlecenia',
                    link: '/new-orders',
                },
                'AcceptedServiceOrders': {
                    label: 'Zaakceptowane zlecenia',
                    link: '/accepted-orders',
                },
                'OngoingServiceOrders': {
                    label: 'Trwające zlecenia',
                    link: '/ongoing-orders',
                },
                'RejectedServiceOrders': {
                    label: 'Odrzucone zlecenia',
                    link: '/rejected-orders',
                },
                'ClosedServiceOrders': {
                    label: 'Zakończone zlecenia',
                    link: '/closed-orders',
                },
            }
        },
        'Shipments': {
            id: 4,
            label: 'Wysyłki',
            collapsable: true,
            open: false,
            icon: <FaIcons.FaTruck/>,
            children: {
                'ShipmentsCompleted': {
                    label: 'Zrealizowane',
                    link: '/shipments-completed'
                },
                'ShipmentsAwaiting': {
                    label: 'Oczekujące',
                    link: '/shipments-awaiting'
                }
            }
        },
        'Users': {
            id: 5,
            label: 'Użytkownicy',
            link: '/users',
            icon: <FaIcons.FaUser/>,
        },
        'Settings': {
            id: 6,
            collapsable: true,
            open: false,
            label: 'Ustawienia',
            icon: <IoIcons.IoIosSettings/>,
            children: {
                'GeneralSettings': {
                    label: 'Ogólne',
                    link: '/general-settings',
                },
                'Connections': {
                    label: 'Powiązania',
                    link: '/connections',
                },
                'Mailing': {
                    label: 'Mailing',
                    link: '/mailing',
                },
                'Logging': {
                    label: 'Logi',
                    link: '/logs',
                },
            }
        },

    }
    const goTo = (link) => {
        redirect(link);
        document.location.reload()
    };
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate("/");
        document.location.reload()
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const prepareMenuList = () => {
        let menuList = []
        for (const [key, value] of Object.entries(permsMapping)) {
            if (perms.includes(key) || value.collapsable) {
                menuList.push(value);
            }
        }
        return menuList;
    }
    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <div className={classes.logoContainer}>
                    <img src={Logo} className={classes.toolbarLogo} alt="keysys logo"/>
                </div>
            </div>
            <Divider/>
            <List>
                {prepareMenuList().map((item) => {
                    return (
                        <NavItem item={item}
                                 perms={perms}
                                 classes={classes}
                                 handleClickOpen={handleOpen}
                                 opened={opened}
                                 setOpened={setOpened}
                                 handleClickClose={handleClickClose}
                        />
                    )
                })}
            </List>
            <Divider/>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <div className={classes.divider}/>
                    <Typography style={{fontSize: '1.2em', color: "black"}}>
                        {currentUser.first_name} {currentUser.last_name}
                    </Typography>
                    <Typography style={{fontSize: '1.2em', width: 100, color: "black"}}>
                        <Clock format={'HH:mm:ss'} ticking={true} style={{margin: '0 20px'}}/>
                    </Typography>
                    <IconButton onClick={handleLogout}>
                        <PowerSettingsNewIcon style={{color: 'black'}}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                The implementation can be swapped with js to avoid SEO duplication of links.
                <Hidden smUp implementation="css">
                    <Drawer
                        // container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}
