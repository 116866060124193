import {createTheme} from '@mui/material/styles';

export const primary = "#FFCB00"
// export const primary= "#2d415a"


export const theme = createTheme({
        typography: {
            fontFamily: ['Poppins'].join(','),
            fontSize: 10,
        },
        palette: {
            primary: {
                main: "#FFCB00",

            },
            secondary: {
                main: "#FFCB00",
            }
        },
        overrides: {
            // Style sheet name ⚛️
            MuiButton: {
                // Name of the rule
                label: {
                    // Some CSS
                    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                    borderRadius: 3,
                    border: 0,
                    color: 'white',
                    height: 48,
                    padding: '0 30px',
                    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                },
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
            },
        }
    })
;