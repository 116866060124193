import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import {Link, useLocation} from 'react-router-dom';

function StandardNavItem({item, classes, handleClose}) {
    const location = useLocation();
    const {hash, pathname, search} = location;
    let isActive = item.link === pathname;
    if (item.children !== undefined) {
        Object.values(item.children).forEach((value) => {
            if (value.link === pathname) {
                isActive = true;
            }
        })
    }
    return (
        <>
            <Link to={item.link} style={{textDecoration: 'none'}}>
                <ListItem
                    button
                    key={item.link}
                    onClick={() => handleClose()}
                    className={`${classes.navIcon} ${isActive ? classes.navIconActive : ''}`}
                >
                    <ListItemIcon disableTypography={true}
                                  style={{color: 'black', fontSize: '30px'}}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} disableTypography={true}
                                  style={{fontWeight: '600', fontSize: 'Large'}}/>
                </ListItem>

            </Link>
        </>
    )
}

export default StandardNavItem;